import React from 'react';

import styles from '../SignUpWorkflow/Signup.module.scss';
import * as Icons from '../UIComponents/VectorIcons';

interface ErrorBoxProps {
  errorMessage: string;
  hasErrored?: boolean;
}

const ErrorBox: React.FC<ErrorBoxProps> = ({
  hasErrored = true,
  errorMessage,
}) => {
  return (
    <div className={styles.infoBox}>
      <span style={{ marginRight: 8 }}>
        {hasErrored ? (
          <Icons.Info_red width={16} height={16} style={{ marginTop: 3 }} />
        ) : (
          <Icons.Info width={16} height={16} style={{ marginTop: 3 }} />
        )}
      </span>
      <span className={hasErrored ? styles.errorText : styles.titleContent}>
        <span dangerouslySetInnerHTML={{ __html: errorMessage }} />
      </span>
    </div>
  );
};

export default ErrorBox;
