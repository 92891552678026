import React, { useEffect, useState } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Autocomplete,
  Button,
  Checkbox,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  TextField,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Formik } from 'formik';
import moment from 'moment';
import { loader } from 'react-global-loader';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import styles from './Signup.module.scss';
import SignupHeader from './SignupHeader';
import {
  checkMemberEligibility,
  getEnrollmentAffiliates,
  getGenders,
} from '../../api/EnrollmentApi';
import { Logger } from '../../App';
import { handleKeyDown, scrollToTop } from '../../helpers/pageHelpers';
import {
  EnrollmentAffiliate,
  EnrollmentAffiliateLookup,
} from '../../interfaces/EnrollmentAffiliate';
import { Gender } from '../../interfaces/Gender';
import { MemberEligibilityRequest } from '../../interfaces/MemberEligibility';
import { MemberInformation } from '../../interfaces/MemberInformation';
import { Store } from '../../store/context';
import { updateMemberInformationForm } from '../../store/providerInfo/actions';
import BeaconButton from '../UIComponents/BeaconButton/BeaconButton';
import ErrorBox from '../UIComponents/ErrorBox';
import * as Icons from '../UIComponents/VectorIcons';

function CollectProviderInfo() {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const { providerInfoState, providerInfoDispatch } = React.useContext(Store);
  const [enrollmentAffiliates, setEnrollmentAffiliates] = useState<
    EnrollmentAffiliate[]
  >([]);
  const [genders, setGenders] = useState<string[]>([]);
  const [preferToSelfIdentifyGender, setPreferToSelfIdentifyGender] =
    useState<Gender>();
  const [isSubmitting, setSubmitting] = useState(false);
  const [submitAttempted, setSubmitAttempted] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [validationModalOpen, setValidationModalOpen] = useState(false);

  const [patientExistsErrorOpen, setPatientExistsErrorOpen] = useState(false);
  const [memberInfo, setMemberInfo] = useState<MemberInformation>(
    providerInfoState.memberInformation
  );

  const pageTitle = t('memberInfo.pageTitle');
  const pageDescription = t('memberInfo.pageDescription');

  useEffect(() => {
    loader.show();
    const provider = new URLSearchParams(location.search).get('provider') || '';
    memberInfo.contractCode =
      new URLSearchParams(location.search).get('contract') || null;
    async function fetchGenders() {
      try {
        const genders = await getGenders();
        setGenders(genders.map((gender) => gender.description));

        const preferToSelfIdentifyGender = genders.find(
          (g) => g.description === 'Prefer to self identify'
        );
        setPreferToSelfIdentifyGender(preferToSelfIdentifyGender);
      } catch (error) {
        loader.hide();
        setErrorModalOpen(true);
        Logger.error(
          error as Error,
          'Failed to get a list of genders.',
          'Collect Provider Info page.'
        );
      }
    }

    async function fetchAffiliates() {
      try {
        const enrollmentAffiliates = await getEnrollmentAffiliates();
        setEnrollmentAffiliates(enrollmentAffiliates);

        const eaLookup = enrollmentAffiliates.reduce((acc, obj) => {
          if (obj.url_param) {
            acc[obj.url_param] = obj;
          }
          return acc;
        }, {} as EnrollmentAffiliateLookup); // { display_string: EnrollmentAffiliate, display_string2: EnrollmentAffiliate2 }

        if (provider && eaLookup[provider]) {
          memberInfo.enrollmentAffiliate = eaLookup[provider];
        }
      } catch (error) {
        loader.hide();
        setErrorModalOpen(true);
        Logger.error(
          error as Error,
          'Failed to get a list of enrollment affiliates.',
          'Collect Provider Info page.'
        );
      }
    }

    fetchAffiliates();
    fetchGenders();
    loader.hide();
  }, [errorModalOpen, patientExistsErrorOpen]);

  const checkEligibilityAndNavigate = async (values: MemberInformation) => {
    let eligibilityResponse = null;
    const memberEligibilityReq: MemberEligibilityRequest = {
      first_name: values.primaryPolicyHolder
        ? values.insuredFirstName
        : values.firstName,
      last_name: values.primaryPolicyHolder
        ? values.insuredLastName
        : values.lastName,
      date_of_birth: values.primaryPolicyHolder
        ? values.insuredDateOfBirth?.toISOString().slice(0, 10)
        : values.dateOfBirth?.toISOString().slice(0, 10),
      enrollment_affiliate_id: values.enrollmentAffiliate
        ? values.enrollmentAffiliate.id
        : '',
    };
    loader.show();
    try {
      eligibilityResponse = await checkMemberEligibility(memberEligibilityReq);
    } catch (error) {
      loader.hide();
      setErrorModalOpen(true);
      Logger.error(
        error as Error,
        'Failed to check for member\'s eligibility.',
        'Collect Provider Info page.'
      );
      return;
    }

    if (eligibilityResponse) {
      loader.hide();
      if (eligibilityResponse.is_eligible) {
        navigate(
          { pathname: '/contactInfo', search: location.search },
          { replace: true }
        );
      } else {
        if (
          eligibilityResponse.error &&
          eligibilityResponse.error === `${t('memberInfo.patientExistsError')}`
        ) {
          setPatientExistsErrorOpen(true);
          return;
        }
        navigate({ pathname: '/ineligible', search: location.search });
      }
    }
  };

  const validationSchema = yup.object().shape({
    enrollmentAffiliate: yup
      .object()
      .required(`${t('memberInfo.insuranceCompanyRequired')}`),
    insurancePolicyID: yup
      .string()
      .when('enrollmentAffiliate', ([enrollmentAffiliate], schema) => {
        return enrollmentAffiliate?.requires_insurance_id == true
          ? schema.required(`${t('memberInfo.insuranceIDRequired')}`)
          : schema;
      }),
    providerReferral: yup.boolean(),
    primaryPolicyHolder: yup.boolean(),
    insuredFirstName: yup
      .string()
      .required(`${t('memberInfo.firstNameRequired')}`),
    insuredLastName: yup
      .string()
      .required(`${t('memberInfo.lastNameRequired')}`),
    insuredDateOfBirth: yup
      .date()
      .nullable()
      .typeError(`${t('memberInfo.dobRequired')}`)
      .min(new Date('01/01/1900'), `${t('memberInfo.dobMin1900')}`)
      .max(
        moment().subtract(18, 'years').toDate(),
        `${t('memberInfo.dobMin18')}`
      )
      .required(`${t('memberInfo.dobRequired')}`),
    firstName: yup
      .string()
      .when('primaryPolicyHolder', ([primaryPolicyHolder], schema) => {
        return primaryPolicyHolder == false
          ? schema.required(`${t('memberInfo.firstNameRequired')}`)
          : schema;
      }),
    lastName: yup
      .string()
      .when('primaryPolicyHolder', ([primaryPolicyHolder], schema) => {
        return primaryPolicyHolder == false
          ? schema.required(`${t('memberInfo.lastNameRequired')}`)
          : schema;
      }),
    dateOfBirth: yup
      .date()
      .nullable()
      .typeError(`${t('memberInfo.dobRequired')}`)
      .min(new Date('01/01/1900'), `${t('memberInfo.dobMin1900')}`)
      .when('primaryPolicyHolder', ([primaryPolicyHolder], schema) => {
        return primaryPolicyHolder == false
          ? schema
            .max(
              moment().subtract(18, 'years').toDate(),
              `${t('memberInfo.dobMin18')}`
            )
            .required(`${t('memberInfo.dobRequired')}`)
          : schema;
      }),
    genderName: yup.string().required('Gender is required'),
    selfDescribedGender: yup
      .string()
      .trim()
      .when('genderName', ([genderName], schema) => {
        return genderName == preferToSelfIdentifyGender?.description
          ? schema.required(`${t('memberInfo.selfIdentifyError')}`)
          : schema;
      }),
    referralSource: yup.object().shape({
      mailReferral: yup.boolean(),
      emailReferral: yup.boolean(),
      phoneReferral: yup.boolean(),
      providerReferral: yup.boolean(),
      otherReferral: yup.boolean(),
    }),
    providerReferralSource: yup.string(),
    otherReferralSource: yup.string(),
  });

  if (errorModalOpen || patientExistsErrorOpen) {
    scrollToTop();
  }

  function renderPrimaryPolicyHolderFields(formik: any) {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} sm={5}>
          {/* Insured First Name */}
          <span className={styles.formlabel}>{t('memberInfo.firstName')} </span>
          <TextField
            id="insurance-holder-first-name-textfield"
            name="insuredFirstName"
            placeholder={`${t('memberInfo.firstNamePlaceholder')}`}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.insuredFirstName}
            error={
              formik.errors.insuredFirstName && formik.touched.insuredFirstName
                ? true
                : false
            }
            fullWidth
            inputProps={{ 'aria-label': t('memberInfo.firstName').toString() }}
          />
          {formik.errors.insuredFirstName &&
            formik.touched.insuredFirstName && (
            <ErrorBox errorMessage={formik.errors.insuredFirstName || ''} />
          )}
        </Grid>

        <Grid item xs={12} sm={5}>
          {/* Insured Last Name */}
          <span className={styles.formlabel}>{t('memberInfo.lastName')}</span>
          <TextField
            id="insurance-holder-last-name-textfield"
            name="insuredLastName"
            placeholder={`${t('memberInfo.lastNamePlaceholder')}`}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.insuredLastName}
            error={
              formik.errors.insuredLastName && formik.touched.insuredLastName
                ? true
                : false
            }
            fullWidth
            inputProps={{ 'aria-label': t('memberInfo.lastName').toString() }}
          />
          {formik.errors.insuredLastName && formik.touched.insuredLastName ? (
            <ErrorBox errorMessage={formik.errors.insuredLastName || ''} />
          ) : null}
        </Grid>

        {formik.values.primaryPolicyHolder && (
          <Grid item xs={12} sm={5}>
            {/* Insured Preferred Name */}
            <span className={styles.formlabel}>
              {t('memberInfo.preferredName')}
            </span>
            <TextField
              id="insurance-holder-preferred-name-textfield"
              name="insuredPreferredName"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.insuredPreferredName}
              error={
                formik.errors.insuredPreferredName &&
                formik.touched.insuredPreferredName
                  ? true
                  : false
              }
              fullWidth
              inputProps={{
                'aria-label': t('memberInfo.preferredName').toString(),
              }}
            />
            <ErrorBox
              errorMessage={
                formik.errors.insuredPreferredName &&
                formik.touched.insuredPreferredName
                  ? formik.errors.insuredPreferredName
                  : t('memberInfo.preferredNameInfo')
              }
              hasErrored={
                formik.errors.insuredPreferredName &&
                formik.touched.insuredPreferredName
                  ? true
                  : false
              }
            />
          </Grid>
        )}

        <Grid item xs={12} sm={5}>
          {/* Insured Date Of Birth */}
          <span className={styles.formlabel}>{t('memberInfo.dob')}</span>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              sx={{ width: '100%' }}
              value={formik.values.insuredDateOfBirth}
              onAccept={(value) => {
                formik.setFieldValue('insuredDateOfBirth', value);
                formik.setFieldTouched('insuredDateOfBirth');
                formik.handleChange;
              }}
              onClose={() => {
                formik.setFieldTouched('insuredDateOfBirth');
                formik.handleChange;
              }}
              onChange={(value) => {
                formik.setFieldValue('insuredDateOfBirth', value);
                formik.handleChange;
              }}
              slotProps={{
                textField: {
                  inputProps: { 'aria-label': t('memberInfo.dob').toString() },
                  id: 'insurance-holder-dob-textfield',
                  error:
                    formik.errors.insuredDateOfBirth &&
                    formik.touched.insuredDateOfBirth
                      ? true
                      : false,
                },
              }}
            />
          </LocalizationProvider>
          <ErrorBox
            errorMessage={
              formik.errors.insuredDateOfBirth &&
              formik.touched.insuredDateOfBirth
                ? formik.errors.insuredDateOfBirth
                : t('memberInfo.dobInfo')
            }
            hasErrored={
              formik.errors.insuredDateOfBirth &&
              formik.touched.insuredDateOfBirth
                ? true
                : false
            }
          />
        </Grid>
      </Grid>
    );
  }

  function renderNonInsuredFields(formik: any) {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} sm={5}>
          {/* First Name */}
          <span className={styles.formlabel}>{t('memberInfo.firstName')}</span>
          <TextField
            id="non-insurance-holder-first-name-textfield"
            name="firstName"
            error={
              formik.errors.firstName && formik.touched.firstName ? true : false
            }
            placeholder={`${t('memberInfo.firstNamePlaceholder')}`}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.firstName}
            fullWidth
          />
          {formik.errors.firstName && formik.touched.firstName && (
            <ErrorBox errorMessage={formik.errors.firstName || ''} />
          )}
        </Grid>

        <Grid item xs={12} sm={5}>
          {/* Last Name */}
          <span className={styles.formlabel}>{t('memberInfo.lastName')} </span>
          <TextField
            id="non-insurance-holder-last-name-textfield"
            name="lastName"
            error={
              formik.errors.lastName && formik.touched.lastName ? true : false
            }
            placeholder={`${t('memberInfo.lastNamePlaceholder')}`}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.lastName}
            fullWidth
          />
          {formik.errors.lastName && formik.touched.lastName && (
            <ErrorBox errorMessage={formik.errors.lastName || ''} />
          )}
        </Grid>

        <Grid item xs={12} sm={5}>
          {/* Preferred Name */}
          <span className={styles.formlabel}>
            {t('memberInfo.preferredName')}
          </span>
          <TextField
            id="non-insurance-holder-preferred-name-textfield"
            name="preferredName"
            error={
              formik.errors.preferredName && formik.touched.preferredName
                ? true
                : false
            }
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.preferredName}
            fullWidth
            inputProps={{
              'aria-label': t('memberInfo.preferredName').toString(),
            }}
          />
          <ErrorBox
            errorMessage={
              formik.errors.preferredName && formik.touched.preferredName
                ? formik.errors.preferredName
                : t('memberInfo.preferredNameInfo')
            }
            hasErrored={
              formik.errors.preferredName && formik.touched.preferredName
                ? true
                : false
            }
          />
        </Grid>

        <Grid item xs={12} sm={5}>
          {/* Date Of Birth */}
          <span className={styles.formlabel}>{t('memberInfo.dob')} </span>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              sx={{ width: '100%' }}
              value={formik.values.dateOfBirth}
              onAccept={(value) => {
                formik.setFieldValue('dateOfBirth', value);
                formik.setFieldTouched('dateOfBirth');
                formik.handleChange;
              }}
              onClose={() => {
                // formik.setFieldValue('dateOfBirth', value);
                formik.setFieldTouched('dateOfBirth');
                formik.handleChange;
              }}
              onChange={(value) => {
                formik.setFieldValue('dateOfBirth', value);
                // formik.setFieldTouched('dateOfBirth');
                formik.validateField('dateOfBirth');
                formik.handleChange;
              }}
              slotProps={{
                textField: {
                  id: 'non-insurance-holder-dob-textfield',
                  error:
                    formik.errors.dateOfBirth && formik.touched.dateOfBirth
                      ? true
                      : false,
                },
              }}
            />
          </LocalizationProvider>
          <ErrorBox
            errorMessage={
              formik.errors.dateOfBirth && formik.touched.dateOfBirth
                ? formik.errors.dateOfBirth
                : t('memberInfo.dobInfo')
            }
            hasErrored={
              formik.errors.dateOfBirth && formik.touched.dateOfBirth
                ? true
                : false
            }
          />
        </Grid>
      </Grid>
    );
  }

  return (
    <>
      <SignupHeader
        title={pageTitle}
        description={pageDescription}
        activeStepNumber={0}
      />
      <Formik
        initialValues={memberInfo}
        enableReinitialize={true}
        validationSchema={validationSchema}
        validateOnChange={true}
        validateOnBlur={true}
        onSubmit={async (values, { setSubmitting, validateForm }) => {
          setSubmitting(true);
          validateForm();
          setSubmitting(false);

          providerInfoDispatch(updateMemberInformationForm(values));

          checkEligibilityAndNavigate(values);
        }}
      >
        {(formik) => {
          const { isValid, dirty, validateForm, errors, isValidating } = formik;

          return (
            <form
              onReset={formik.handleReset}
              onSubmit={formik.handleSubmit}
              onKeyDown={(e) => {
                handleKeyDown(e);
              }}
            >
              <div className={styles.form}>
                <Container maxWidth="sm" className={styles.container}>
                  <Grid container spacing={1}>
                    <Grid item xs={5} sm={10}>
                      <h4 className={styles.title}>
                        {t('memberInfo.pageHeader')}
                      </h4>
                    </Grid>
                    <Grid item xs={7} sm={2} textAlign="right">
                      <BeaconButton />
                    </Grid>
                  </Grid>
                  {formik.errors && submitAttempted && (
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        {Object.values(formik.errors).map((e, idx) => (
                          <ErrorBox errorMessage={e} key={idx} />
                        ))}
                      </Grid>
                    </Grid>
                  )}
                  <Grid container spacing={1}>
                    <Grid item>
                      <span className={styles.titleContent}>
                        {t('memberInfo.insuranceInfo')}
                      </span>
                    </Grid>
                  </Grid>
                  {patientExistsErrorOpen && (
                    <Grid container>
                      <Grid item>
                        <div className={styles.patientExistsBox}>
                          <div style={{ marginRight: 8 }}>
                            <Icons.Info_red
                              width={16}
                              height={16}
                              style={{ marginTop: 3 }}
                            />
                          </div>
                          <span
                            className={styles.errorText}
                            style={{ display: 'inline-block' }}
                          >
                            {t('memberInfo.patientExistsIneligibleMessage')}
                            <a href={`${t('memberInfo.loginLink')}`}>
                              {t('memberInfo.patientExistsIneligibleMessage1')}
                            </a>
                            {t('memberInfo.patientExistsIneligibleMessage2')}
                            <a href={`mailto:${t('memberInfo.supportEmail')}`}>
                              {t('memberInfo.supportEmail')}
                            </a>
                          </span>
                        </div>
                      </Grid>
                    </Grid>
                  )}
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={10}>
                      <span className={styles.formlabel}>
                        {t('memberInfo.insuranceCompany')}
                      </span>
                      <Autocomplete
                        disablePortal
                        id="provider-autocomplete"
                        options={enrollmentAffiliates.filter(
                          (ea) => ea.display_to_user
                        )}
                        getOptionLabel={(ea) => ea.display_string}
                        value={formik.values.enrollmentAffiliate}
                        noOptionsText={`${t('memberInfo.noResultsFound')}`}
                        popupIcon={<ExpandMoreIcon fontSize="large" />}
                        disabled={
                          formik.values.enrollmentAffiliate != null &&
                          !formik.values.enrollmentAffiliate.display_to_user
                        }
                        readOnly={
                          formik.values.enrollmentAffiliate != null &&
                          !formik.values.enrollmentAffiliate.display_to_user
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={
                              formik.errors.enrollmentAffiliate &&
                              formik.touched.enrollmentAffiliate
                                ? true
                                : false
                            }
                            inputProps={{
                              ...params.inputProps,
                              'aria-label': t(
                                'memberInfo.insuranceCompany'
                              ).toString(),
                            }}
                          />
                        )}
                        onChange={(event, newValue) => {
                          formik.setFieldValue('enrollmentAffiliate', newValue);
                        }}
                        onBlur={() =>
                          formik.setFieldTouched('enrollmentAffiliate', true)
                        }
                      />
                      {formik.errors.enrollmentAffiliate &&
                      formik.touched.enrollmentAffiliate ? (
                          <ErrorBox
                            errorMessage={formik.errors.enrollmentAffiliate || ''}
                          />
                        ) : null}
                    </Grid>
                  </Grid>

                  {formik.values.enrollmentAffiliate?.contract
                    ?.requires_insurance_id && (
                    <>
                      <Grid container spacing={2}>
                        <Grid item>
                          <h3 className={styles.checkboxTitle}>
                            {t('memberInfo.primaryInsuranceHolderInfo')}
                          </h3>
                          <FormControlLabel
                            className={styles.checkboxGroup}
                            name="primaryPolicyHolder"
                            style={{ display: 'table' }}
                            control={
                              <div style={{ display: 'table-cell' }}>
                                <Checkbox
                                  id="not-policy-holder-checkbox"
                                  checked={!formik.values.primaryPolicyHolder}
                                />
                              </div>
                            }
                            onChange={() => {
                              formik.setFieldValue(
                                'primaryPolicyHolder',
                                !formik.values.primaryPolicyHolder
                              );
                            }}
                            label={
                              <div className="disclaimerandnotes">
                                {t('memberInfo.notPrimary')}
                              </div>
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sm={8}>
                          <span className={styles.formlabel}>
                            {t('memberInfo.insuranceIDNumber')}{' '}
                          </span>
                          <TextField
                            id="insurance-policy-id-textfield"
                            name="insurancePolicyID"
                            placeholder="xxx-xxxx-xxx-xxxx"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.insurancePolicyID}
                            fullWidth
                            error={
                              formik.errors.insurancePolicyID &&
                              formik.touched.insurancePolicyID
                                ? true
                                : false
                            }
                          />
                          {formik.errors.insurancePolicyID &&
                          formik.touched.insurancePolicyID ? (
                              <ErrorBox
                                errorMessage={
                                  formik.errors.insurancePolicyID || ''
                                }
                              />
                            ) : null}
                        </Grid>
                      </Grid>
                      {
                        !formik.values.primaryPolicyHolder &&
                          renderPrimaryPolicyHolderFields(formik) // eslint-disable-line
                      }
                      <Grid item xs={12}>
                        {!formik.values.primaryPolicyHolder && (
                          <>
                            <hr className={styles.line} />
                            <h3
                              className={`${styles.titleMedium} ${styles.secondSectionHeader}`}
                            >
                              {t('memberInfo.yourInfo')}
                            </h3>
                          </>
                        )}
                      </Grid>
                    </>
                  )}

                  {formik.values.primaryPolicyHolder
                    ? renderPrimaryPolicyHolderFields(formik)
                    : renderNonInsuredFields(formik)}

                  <Grid container rowSpacing={1}>
                    <Grid item xs={12} sm={6}>
                      {/* Gender */}
                      <FormControl fullWidth>
                        <span className={styles.formlabel}>
                          {t('memberInfo.gender')}
                        </span>
                        <Autocomplete
                          disablePortal
                          id="gender-autocomplete"
                          options={genders}
                          value={formik.values.genderName}
                          noOptionsText={`${t('memberInfo.noResultsFound')}`}
                          popupIcon={<ExpandMoreIcon fontSize="large" />}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              error={
                                !!(
                                  formik.errors.genderName &&
                                  formik.touched.genderName
                                )
                              }
                              inputProps={{
                                ...params.inputProps,
                                'aria-label': t('memberInfo.gender').toString(),
                              }}
                            />
                          )}
                          onChange={(event, gender) => {
                            formik.setFieldValue('genderName', gender);
                          }}
                          onBlur={() =>
                            formik.setFieldTouched('genderName', true)
                          }
                        />
                        {formik.errors.genderName &&
                        formik.touched.genderName ? (
                            <ErrorBox
                              errorMessage={formik.errors.genderName || ''}
                            />
                          ) : null}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {formik.values.genderName &&
                    formik.values.genderName ===
                      preferToSelfIdentifyGender?.description && (
                    <>
                      <Grid container rowSpacing={1}>
                        <Grid item xs={12} sm={6}>
                          <span className={styles.formlabel}>
                            {t('memberInfo.selfIdentify')}
                          </span>
                          <TextField
                            id="self-described-gender-textfield"
                            name="selfDescribedGender"
                            placeholder=""
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.selfDescribedGender}
                            error={
                              formik.errors.selfDescribedGender &&
                                formik.touched.selfDescribedGender
                                ? true
                                : false
                            }
                            fullWidth
                            inputProps={{
                              'aria-label': t(
                                'memberInfo.selfIdentify'
                              ).toString(),
                            }}
                          />
                          {formik.errors.selfDescribedGender &&
                            formik.touched.selfDescribedGender ? (
                              <ErrorBox
                                errorMessage={
                                  formik.errors.selfDescribedGender || ''
                                }
                              />
                            ) : null}
                        </Grid>
                      </Grid>
                    </>
                  )}

                  {/* Referral Source */}
                  <FormControl>
                    <span className={styles.checkboxTitle}>
                      {t('memberInfo.howDidYouHear')}{' '}
                      <span>({t('memberInfo.optional')})</span>
                    </span>
                    <span className={styles.checkboxSubtitle}>
                      {t('memberInfo.selectAllApply')}
                    </span>

                    <FormControlLabel
                      className={styles.checkboxGroup}
                      control={
                        <Checkbox
                          id="mail-referral-checkbox"
                          className={styles.checkboxGroup}
                          name="referralSource.mailReferral" //event.target.name matches the state key in handleReferral source
                          checked={formik.values.referralSource?.mailReferral}
                          onChange={() => {
                            formik.setFieldValue(
                              'referralSource.mailReferral',
                              !formik.values.referralSource?.mailReferral
                            );
                          }}
                        />
                      }
                      label={t('memberInfo.mail')}
                    />
                    <FormControlLabel
                      className={styles.checkboxGroup}
                      control={
                        <Checkbox
                          id="email-referral-checkbox"
                          className={styles.checkboxGroup}
                          name="referralSource.emailReferral"
                          checked={formik.values.referralSource?.emailReferral}
                          onChange={() => {
                            formik.setFieldValue(
                              'referralSource.emailReferral',
                              !formik.values.referralSource?.emailReferral
                            );
                          }}
                        />
                      }
                      label={t('memberInfo.email')}
                    />
                    <FormControlLabel
                      className={styles.checkboxGroup}
                      control={
                        <Checkbox
                          id="phone-referral-checkbox"
                          className={styles.checkboxGroup}
                          name="referralSource.phoneReferral"
                          checked={formik.values.referralSource?.phoneReferral}
                          onChange={() => {
                            formik.setFieldValue(
                              'referralSource.phoneReferral',
                              !formik.values.referralSource?.phoneReferral
                            );
                          }}
                        />
                      }
                      label={t('memberInfo.phoneCall')}
                    />
                    <FormControlLabel
                      className={styles.checkboxGroup}
                      control={
                        <Checkbox
                          id="social-media-referral-checkbox"
                          name="referralSource.providerReferral"
                          checked={
                            formik.values.referralSource?.providerReferral
                          }
                          onChange={() => {
                            formik.setFieldValue(
                              'referralSource.providerReferral',
                              !formik.values.referralSource?.providerReferral
                            );
                          }}
                        />
                      }
                      label={t('memberInfo.doctorReferral')}
                    />

                    {formik.values.referralSource?.providerReferral ? (
                      <div className={styles.IndentedFormControl}>
                        {/* doctor name */}
                        <FormControl className={styles.FormControl}>
                          <span className={styles.formlabel}>
                            {t('memberInfo.clinicName')}:{' '}
                          </span>
                          <TextField
                            id="provider-referral-source-textfield"
                            name="providerReferralSource"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.providerReferralSource}
                          />
                        </FormControl>
                      </div>
                    ) : null}

                    <FormControlLabel
                      className={styles.checkboxGroup}
                      control={
                        <Checkbox
                          id="other-referral-checkbox"
                          name="referralSource.otherReferral"
                          checked={formik.values.referralSource?.otherReferral}
                          onChange={formik.handleChange}
                        />
                      }
                      label={t('memberInfo.other')}
                    />
                  </FormControl>

                  {formik.values.referralSource?.otherReferral ? (
                    <div className={styles.IndentedFormControl}>
                      {/* Other referral source */}
                      <FormControl className={styles.FormControl}>
                        <TextField
                          id="other-referral-source-textfield"
                          name="otherReferralSource"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.otherReferralSource}
                        />
                        {formik.errors.otherReferralSource &&
                          formik.touched.otherReferralSource && (
                          <div className={styles.errorText}>
                            {formik.errors.otherReferralSource}
                          </div>
                        )}
                      </FormControl>
                    </div>
                  ) : null}

                  {isSubmitting && <div>{t('memberInfo.loading')}</div>}
                  <Grid container justifyContent="flex-end">
                    <Button
                      id="continue-button"
                      variant="text"
                      disabled={isSubmitting}
                      className={'wl-button'}
                      type="submit"
                      onClick={() => {
                        setSubmitAttempted(true);
                        if (!formik.isValid || !formik.dirty) {
                          scrollToTop();
                        }
                      }}
                    >
                      {t('memberInfo.continue')}
                    </Button>
                  </Grid>
                </Container>
              </div>
            </form>
          );
        }}
      </Formik>

      <Dialog open={errorModalOpen}>
        <DialogTitle>{t('memberInfo.contentError')}</DialogTitle>
        <DialogContent>{t('memberInfo.errorLoading')}</DialogContent>
      </Dialog>
      <Dialog open={validationModalOpen}>
        <DialogTitle>{t('memberInfo.wellinksEnrollment')}</DialogTitle>
        <DialogContent>
          {t('memberInfo.pleaseComplete')}
          <Button
            id="ok-button"
            variant="contained"
            onClick={() => {
              setValidationModalOpen(false);
            }}
          >
            {t('memberInfo.ok')}
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default CollectProviderInfo;

function useSelector(arg0: (state: any) => any) {
  throw new Error('Function not implemented.');
}

function dispatch(arg0: { payload: any; type: 'ui/showRouteLeaveDialogue' }) {
  throw new Error('Function not implemented.');
}
