import { isMobile } from 'react-device-detect';

export const scrollToTop = () => {
  window.scrollTo(0, 0);
};

// blocks enter key from page submission on mobile
export const handleKeyDown = (event: any) => {
  if (isMobile && event.key === 'Enter' && event.shiftKey === false) {
    event.preventDefault();
  }
};
