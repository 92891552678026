/*
    Embedding helpscout's beacon script at the head is required for this component to work. 
    Additionally, helpscout's embed code contains two script tags. One to import beacon and 
    one to initialize it.

    Add Beacon:
    https://docs.helpscout.com/article/1356-add-beacon-to-your-website-or-app

    Beacon JS Api
    https://developer.helpscout.com/beacon-2/web/javascript-api/
*/
import React from 'react';

import { Button } from '@mui/material';
import { IoIosHelpCircle } from 'react-icons/io';

import { Logger } from '../../../App';
declare let window: any; // required because of typescript

export interface BeaconButtonProps {
  beaconId: string;
}

const BeaconButton = () => {
  function toggleBeacon() {
    try {
      window.Beacon('toggle');
    } catch (error) {
      console.error(
        'Beacon must be embedded in the head of the dom. See component documentation.'
      );
      Logger.error(
        error as Error,
        'Beacon must be embedded in the head of the dom. See component documentation.',
        'Beacon Button'
      );
    }
  }

  return (
    <Button
      id="btn-launch-beacon"
      startIcon={<IoIosHelpCircle />}
      variant="outlined"
      sx={{
        borderColor: '#F7A525',
        textTransform: 'none',
        borderRadius: '70px',
        borderWidth: '3px',
        color: '#202828',
        fontSize: '13px',
        '&:hover': {
          backgroundColor: 'transparent',
          borderColor: '#F7A525',
          borderWidth: '3px',
        },
      }}
      onClick={toggleBeacon}
    >
      Help
    </Button>
  );
};

export default BeaconButton;
