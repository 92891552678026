import React from 'react';

import Lottie from 'lottie-react';

import './loader.css';
import loaderAnimation from '../../assets/animations/loader.json';

const Loader = () => {
  return (
    <div className="global-spinner-overlay">
      <div style={{ width: 200, height: 200 }}>
        <Lottie
          animationData={loaderAnimation}
          loop={true}
          autoPlay={true}
          rendererSettings={{ preserveAspectRatio: 'xMidYMid slice' }}
        />
      </div>
    </div>
  );
};

export default Loader;
