import React from 'react';

import { Container, Grid, useTheme } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import ReactPlayer from 'react-player';
import { useNavigate } from 'react-router-dom';

import styles from './Signup.module.scss';
import SignupHeader from './SignupHeader';

function Confirmation() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const pageTitle = t('confirmation.pageTitle');
  const pageDescription = t('confirmation.pageDescription');

  const theme = useTheme();

  return (
    <div>
      <SignupHeader
        title={pageTitle}
        description={pageDescription}
        activeStepNumber={4}
      />
      <div
        className={styles.confirmationTitleContent}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <strong>{t('confirmation.pageHeader')}</strong>
      </div>
      <div className={styles.form} style={{ paddingTop: 24 }}>
        <Container maxWidth="sm">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <span className="confirmationDescription">
                <Trans
                  i18nKey="confirmation.content1"
                  components={{ bold: <strong /> }}
                />
                <a href={`mailto:${t('memberInfo.supportEmail')}`}>
                  {t('memberInfo.supportEmail')}
                </a>
              </span>
            </Grid>
            <Grid item xs={12}>
              <span className="confirmationDescription">
                <Trans
                  i18nKey="confirmation.content2"
                  components={{ bold: <strong /> }}
                />
              </span>
            </Grid>
            <Grid item xs={12}>
              <span className="confirmationDescription">
                {t('confirmation.content3')}
              </span>
            </Grid>
            <Grid item xs={12}>
              <ReactPlayer
                url="https://vimeo.com/829560361/f0215b363c?share=copy"
                controls
                width={'100%'}
              />
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  );
}

export default Confirmation;
