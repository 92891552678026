import { UPDATE_MEMBER_FORM } from './actions';
import { Action } from '../../interfaces/Action';
import { SignupState } from '../../interfaces/SignupState';

export const providerInfoInitialState: SignupState = {
  memberInformation: {
    enrollmentAffiliate: null,
    contractCode: null,
    primaryPolicyHolder: true,
    insurancePolicyID: '',
    firstName: '',
    lastName: '',
    dateOfBirth: null,
    insuredFirstName: '',
    insuredLastName: '',
    insuredPreferredName: '',
    insuredDateOfBirth: null,
    preferredName: '',
    genderName: null,
    selfDescribedGender: '',
    referralSource: {
      mailReferral: false,
      emailReferral: false,
      phoneReferral: false,
      providerReferral: false,
      otherReferral: false,
    },
    otherReferralSource: '',
    providerReferralSource: '',
  },
};

const providerInfo = (state: SignupState, action: Action) => {
  switch (action.type) {
  case UPDATE_MEMBER_FORM: {
    return { ...state, memberInformation: action.payload };
  }
  default: {
    return state;
  }
  }
};

export default providerInfo;
