import { UPDATE_CONTACT_FORM } from './actions';
import { Action } from '../../interfaces/Action';

export const contactInfoInitialState = {
  phoneNumber: '',
  preferPhoneCall: false,
  addressLine1: '',
  addressLine2: '',
  city: '',
  state: '',
  state_abbr: '',
  zip: '',
  privacyPolicyAccepted: false,
  consentForCareAccepted: false,
  submitted: false,
};

const contactInfo = (state: any, action: Action) => {
  switch (action.type) {
  case UPDATE_CONTACT_FORM: {
    return { ...state, ...action.payload, submitted: true };
  }
  default: {
    return state;
  }
  }
};

export default contactInfo;
