import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import EN from './locales/en.json';

const resources = {
  en: {
    translation: EN,
  },
};

const supportedLanguages = ['en'];

i18n.use(initReactI18next).init({
  fallbackLng: 'en',
  lng: 'en',
  supportedLngs: supportedLanguages,
  debug: true,
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  resources: resources,
});

export default i18n;
