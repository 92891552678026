import React from 'react';

import classes from './PageContent.module.scss';

const PageContent = ({ title, children }: { title: string; children: any }) => {
  return (
    <div className={classes.content}>
      <h1>{title}</h1>
      {children}
    </div>
  );
};

export default PageContent;
