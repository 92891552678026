import React from 'react';
import './Footer.scss';

class Footer extends React.Component {
  render() {
    return (
      <footer>
        <div className="container">
          <div className="footer-top_component">
            <h1 className="footer-top_heading">resources</h1>
            <div className="footer-top_links-wrap">
              <a
                href={`${process.env.REACT_APP_MARKETING_SITE_URL}/privacy-policy`}
                target="_blank"
                className="footer-top_link"
                rel="noreferrer"
              >
                Privacy Policy
              </a>
              <a
                href={`${process.env.REACT_APP_MARKETING_SITE_URL}/terms-of-service`}
                target="_blank"
                className="footer-top_link"
                rel="noreferrer"
              >
                Terms of Service
              </a>
              <a
                href={`${process.env.REACT_APP_MARKETING_SITE_URL}/hipaa-privacy`}
                target="_blank"
                className="footer-top_link"
                rel="noreferrer"
              >
                HIPAA
              </a>
              <a
                href={`${process.env.REACT_APP_MARKETING_SITE_URL}/careers`}
                target="_blank"
                className="footer-top_link"
                rel="noreferrer"
              >
                Careers
              </a>
              <a
                href={`${process.env.REACT_APP_MARKETING_SITE_URL}/contact`}
                target="_blank"
                className="footer-top_link"
                rel="noreferrer"
              >
                Contact Us
              </a>
            </div>
          </div>
          <div className="footer-bottom_component">
            <div className="footer-bottom_wrap">
              <div className="footer-bottom-wrap">
                <img
                  src="https://uploads-ssl.webflow.com/614a1ca9934d3f7e2d47575f/6154c2599a78ed3a4af7436f_rgb_wellinks_icon_one-color_white%202.svg"
                  loading="eager"
                  alt=""
                  className="footer-bottom_icon"
                />
                <div className="contact-us_all-contacts">
                  <div className="contact-us_contact-item">
                    <img
                      src="https://uploads-ssl.webflow.com/614a1ca9934d3f7e2d47575f/614a1ca9934d3fcc5f4757d3_location-icon.svg"
                      loading="eager"
                      alt="Location Icon"
                      className="contact-us_icon"
                    />
                    <div className="contact-us_text">
                      85 Willow Street New Haven, CT 06511
                    </div>
                  </div>
                  <div
                    id="w-node-_28bf5b6f-6393-ac5a-8f87-b31511f3d158-cc8223b7"
                    className="contact-us_contact-item"
                  >
                    <img
                      src="https://uploads-ssl.webflow.com/614a1ca9934d3f7e2d47575f/614a1ca9934d3fba0f4757d2_email-icon.svg"
                      loading="eager"
                      alt="Email Icon"
                      className="contact-us_icon"
                    />
                    <a
                      href="mailto:ask@wellinks.com"
                      target="_blank"
                      className="contact-us_link-block w-inline-block"
                      rel="noreferrer"
                    >
                      <div className="contact-us_text">ask@wellinks.com</div>
                    </a>
                  </div>
                </div>
              </div>
              <div>
                <div className="footer-bottom_copyright-text">
                  © <span id="year">2022</span> Wellinks
                </div>
                <div className="contact-us_all-social">
                  <div className="contact-us_social-icon-wrap">
                    <a
                      href="https://www.facebook.com/Wellinks/"
                      target="_blank"
                      className="contact-us_link-block w-inline-block"
                      rel="noreferrer"
                    >
                      <img
                        src="https://uploads-ssl.webflow.com/614a1ca9934d3f7e2d47575f/614a1ca9934d3f78464757d5_Facebook.svg"
                        loading="eager"
                        alt="Facebook Icon"
                        className="contact-us_social-icon"
                      />
                    </a>
                  </div>
                  <div className="contact-us_contact-item">
                    <a
                      href="https://twitter.com/wellinkshealth?lang=en"
                      target="_blank"
                      className="contact-us_link-block w-inline-block"
                      rel="noreferrer"
                    >
                      <img
                        src="https://uploads-ssl.webflow.com/614a1ca9934d3f7e2d47575f/614a1ca9934d3f14134757cf_Twitter.svg"
                        loading="eager"
                        alt="Twitter Icon"
                        className="contact-us_social-icon"
                      />
                    </a>
                  </div>
                  <div className="contact-us_contact-item">
                    <a
                      href="https://www.linkedin.com/company/wellinkshealth/"
                      target="_blank"
                      className="contact-us_link-block w-inline-block"
                      rel="noreferrer"
                    >
                      <img
                        src="https://uploads-ssl.webflow.com/614a1ca9934d3f7e2d47575f/614a1ca9934d3f4b914757d1_LinkedIn.svg"
                        loading="eager"
                        alt="LinkedIn Icon"
                        className="contact-us_social-icon"
                      />
                    </a>
                  </div>
                  <div className="contact-us_contact-item">
                    <a
                      href="https://www.instagram.com/wellinks/?hl=en"
                      target="_blank"
                      className="contact-us_link-block w-inline-block"
                      rel="noreferrer"
                    >
                      <img
                        src="https://uploads-ssl.webflow.com/614a1ca9934d3f7e2d47575f/614a1ca9934d3f1fb24757d0_IG.svg"
                        loading="eager"
                        alt="Instagram Icon"
                        className="contact-us_social-icon"
                      />
                    </a>
                  </div>
                </div>
              </div>
              <img
                src="https://uploads-ssl.webflow.com/614a1ca9934d3f7e2d47575f/63ec4710caa31a1336aa6e06_Footer%20Logo.png"
                loading="lazy"
                alt="SOC 2 Achieved"
                className="footer-logo"
              ></img>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
