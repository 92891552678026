import {
  AvailableSlotsForRangeQuery,
  CompleteCheckoutMutation,
  DaysAvailableForRangeQuery,
  SignUpInput,
  SignUpMutation,
  UpdateClientInput,
  UpdateClientMutation,
} from '@wellinks/wellinks-healthie/types/src/generated/graphql';
import { UPDATE_CLIENT_MUTATION } from '@wellinks/wellinks-healthie/types/src/graphql/mutations/mutations';
import { SIGNUP_PATIENT_MUTATION } from '@wellinks/wellinks-healthie/types/src/graphql/mutations/mutations';
import { COMPLETE_CHECKOUT_MUTATION } from '@wellinks/wellinks-healthie/types/src/graphql/mutations/mutations';
import {
  AVAILABLE_SLOTS_FOR_RANGE,
  DAYS_AVAILABLE_FOR_RANGE,
} from '@wellinks/wellinks-healthie/types/src/graphql/queries/queries';
import { GraphQLClient } from 'graphql-request';

import { Logger } from '../App';
import {
  AppointmentBookingRequest,
  CreatePatientRequest,
} from '../interfaces/Healthie';

if (!process.env.REACT_APP_HEALTHIE_API_URL) {
  throw new Error('Healthie API Url is not set.');
}

const healthieClient: GraphQLClient = new GraphQLClient(
  `${process.env.REACT_APP_HEALTHIE_API_URL}/graphql`
);

const healthieAuthenticatedClient = (): GraphQLClient => {
  healthieClient.setHeader(
    'Authorization',
    `Bearer ${sessionStorage.getItem('hwt')}`
  );
  return healthieClient;
};

export const signUpPatient = async (
  request: CreatePatientRequest
): Promise<SignUpMutation> => {
  const HEALTHIE_SUPPORT_ACCOUNT_ID =
    process.env.REACT_APP_HEALTHIE_SUPPORT_ACCOUNT_ID?.toString();
  const HEALTHIE_SCHEDULING_GROUP_INVITE_CODE =
    process.env.REACT_APP_HEALTHIE_SCHEDULING_GROUP_INVITE_CODE?.toString();

  if (!HEALTHIE_SUPPORT_ACCOUNT_ID) {
    throw new Error('Healthie Support Account User Id is not set.');
  }
  if (!HEALTHIE_SCHEDULING_GROUP_INVITE_CODE) {
    throw new Error('Healthie Scheduling Group Invite Code is not set.');
  }

  const signUpInput: SignUpInput = {
    email: request.email,
    password: request.password,
    first_name: request.firstName,
    last_name: request.lastName,
    phone_number: request.phoneNumber,
    legal_name: request.legalName,
    dietitian_id: HEALTHIE_SUPPORT_ACCOUNT_ID,
    invite_code: HEALTHIE_SCHEDULING_GROUP_INVITE_CODE,
    role: 'patient',
  };

  try {
    const response: SignUpMutation = await healthieClient.request(
      SIGNUP_PATIENT_MUTATION,
      signUpInput
    );

    return response;
  } catch (error) {
    Logger.error(error as Error, 'Unable to sign up member', 'HealthieAPI');
    throw new Error(`Unable to signup member: ${error}`);
  }
};

export const updateHealthiePatient = async () => {
  healthieAuthenticatedClient();
  throw new Error('Not implemented');
};

export const getAvailableAppointmentDaysByMonth = async (
  providerID: string,
  dateFrom: string,
  appointmentTypeID: string,
  timezone: string
): Promise<DaysAvailableForRangeQuery> => {
  try {
    const response: DaysAvailableForRangeQuery = await healthieClient.request(
      DAYS_AVAILABLE_FOR_RANGE,
      {
        provider_id: providerID, //irrelephant, just has to be passed
        date_from_month: dateFrom,
        org_level: true,
        timezone: timezone,
        appt_type_id: appointmentTypeID,
      }
    );

    return response;
  } catch (error) {
    Logger.error(
      error as Error,
      'Unable to fetch calendar dates',
      'HealthieAPI'
    );
    throw new Error(`Unable to fetch calendar dates`);
  }
};

export const getAvailableAppointmentTimes = async (
  providerID: string,
  date: string,
  appointmentTypeID: string,
  timezone: string
): Promise<AvailableSlotsForRangeQuery> => {
  try {
    const response: AvailableSlotsForRangeQuery = await healthieClient.request(
      AVAILABLE_SLOTS_FOR_RANGE,
      {
        provider_id: providerID,
        start_date: date,
        end_date: date,
        org_level: true,
        timezone: timezone,
        appt_type_id: appointmentTypeID,
      }
    );

    return response;
  } catch (error) {
    Logger.error(
      error as Error,
      'Unable to fetch calendar times',
      'HealthieAPI'
    );
    throw new Error(`Unable to fetch calendar times`);
  }
};

export const bookAppointment = async (
  request: AppointmentBookingRequest
): Promise<CompleteCheckoutMutation> => {
  try {
    const response: CompleteCheckoutMutation = await healthieClient.request(
      COMPLETE_CHECKOUT_MUTATION,
      {
        email: request.email,
        phone_number: request.phone_number,
        contact_type: request.contact_type,
        timezone: request.timezone,
        appointment_id: request.appointment_id,
        provider_id: request.provider_id,
        date: request.date,
        appointment_type_id: request.appointment_type_id,
      }
    );

    return response;
  } catch (error) {
    Logger.error(error as Error, 'Unable to book appointment', 'HealthieAPI');
    throw new Error(`Unable to book appointment`);
  }
};

export const updateHealthiePatientProvider = async (
  healthieID: string,
  dietitianID: string
) => {
  try {
    const response = healthieClient.request(UPDATE_CLIENT_MUTATION, {
      id: healthieID,
      dietitian_id: dietitianID,
    });
    return response;
  } catch (error) {
    Logger.error(
      error as Error,
      'Unable to update client provider',
      'HealthieAPI'
    );
  }
};

export const updateHealthieClientInfo = async (
  id: string,
  record_identifier: string | null,
  dob: string,
  gender: string | null,
  gender_identity: string | null,
  address_line_1: string,
  address_line_2: string | null,
  city: string,
  state: string,
  zip: string,
  country: string
): Promise<UpdateClientMutation> => {
  try {
    healthieAuthenticatedClient();
    const response: UpdateClientMutation = await healthieClient.request(
      UPDATE_CLIENT_MUTATION,
      {
        id: id,
        record_identifier: record_identifier,
        dob: dob,
        gender: gender,
        gender_identity: gender_identity,
        location: {
          line1: address_line_1,
          line2: address_line_2,
          city: city,
          state: state,
          zip: zip,
          country: country,
        },
      } as UpdateClientInput
    );

    return response;
  } catch (error) {
    Logger.error(error as Error, 'Unable to update client', 'HealthieAPI');
    throw new Error(`Unable to update client`);
  }
};
