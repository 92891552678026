import { UPDATE_CREDENTIALS_FORM } from './actions';
import { Action } from '../../interfaces/Action';

export const credentialsInfoInitialState = {
  credentialsInformation: {
    email: '',
    password: '',
  },
};

const credentialsInfo = (state: any, action: Action) => {
  switch (action.type) {
  case UPDATE_CREDENTIALS_FORM: {
    return { ...state, credentialsInformation: action.payload };
  }
  default: {
    return state;
  }
  }
};

export default credentialsInfo;
