import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import './Header.scss';
const Header = () => {
  const { t } = useTranslation();
  const [isMobileNavBarEnable, setMobileNavBarEnable] = useState(false);
  useEffect(() => {
    const resizeListener = () => {
      setMobileNavBarEnable(false);
    };
    // set resize listener
    window.addEventListener('resize', resizeListener);

    // clean up function
    return () => {
      // remove resize listener
      window.removeEventListener('resize', resizeListener);
    };
  }, []);

  useEffect(() => {
    const container = document.getElementById('mobile-nav-container');
    container?.addEventListener('click', toggleNavMenu);

    return () => {
      container?.removeEventListener('click', toggleNavMenu);
    };
  }, [isMobileNavBarEnable]);

  const toggleNavMenu = () => {
    setMobileNavBarEnable(!isMobileNavBarEnable);
  };
  return (
    <header>
      <div
        data-collapse="medium"
        data-animation="default"
        data-duration="400"
        data-easing="ease"
        data-easing2="ease"
        role="banner"
        className="navbar w-nav"
      >
        <div className="navbar-wrap">
          <a
            href={process.env.REACT_APP_MARKETING_SITE_URL}
            target="_blank"
            className="brand w-nav-brand"
            aria-label="home"
            rel="noreferrer"
          >
            <img
              src="https://uploads-ssl.webflow.com/614a1ca9934d3f7e2d47575f/61645b9228d9cf22fbfe8f96_Logo%20(1).png"
              loading="eager"
              alt=""
              className="logo"
            />
          </a>
          <nav role="navigation" className="nav-menu w-nav-menu">
            <a
              href={`${process.env.REACT_APP_MARKETING_SITE_URL}/for-patients`}
              target="_blank"
              className="nav-link type-2 w-nav-link"
              rel="noreferrer"
            >
              For Patients
            </a>
            <a
              href={`${process.env.REACT_APP_MARKETING_SITE_URL}/for-payers-and-providers`}
              target="_blank"
              className="nav-link type-2 w-nav-link"
              rel="noreferrer"
            >
              For Payers & Providers
            </a>
            <a
              href={`${process.env.REACT_APP_MARKETING_SITE_URL}/news`}
              target="_blank"
              className="nav-link type-2 w-nav-link"
              rel="noreferrer"
            >
              News & Literature
            </a>
            <a
              href={`${process.env.REACT_APP_MARKETING_SITE_URL}/about`}
              target="_blank"
              aria-current="page"
              className="nav-link type-2 w-nav-link"
              rel="noreferrer"
            >
              About
            </a>
            <a
              href={`${process.env.REACT_APP_MARKETING_SITE_URL}/contact`}
              target="_blank"
              className="button-style-1 nav-bar w-button"
              aria-label={t('header.getInTouch').toString()}
              rel="noreferrer"
            >
              Get in touch
            </a>
          </nav>
          <div
            className="menu-button w-nav-button"
            //   style="-webkit-user-select: text;"
            aria-label="menu"
            role="button"
            tabIndex={0}
            aria-controls="w-nav-overlay-0"
            aria-haspopup="menu"
            aria-expanded="false"
          >
            <div
              className="w-icon-nav-menu"
              onClick={() => toggleNavMenu()}
            ></div>
          </div>
        </div>

        <div className="w-nav-overlay" id="w-nav-overlay-0">
          {isMobileNavBarEnable && (
            <nav
              id="mobile-nav-container"
              role="navigation"
              className="nav-menu w-nav-menu"
              style={{
                transform: 'translateY(0px)',
                transition: 'transform 400ms ease 0s',
              }}
              data-nav-menu-open=""
            >
              <div className="mobile-nav-items">
                <a
                  href={`${process.env.REACT_APP_MARKETING_SITE_URL}/for-patients`}
                  target="_blank"
                  className="nav-link type-2 w-nav-link w--nav-link-open"
                  rel="noreferrer"
                >
                  For Patients
                </a>
                <a
                  href={`${process.env.REACT_APP_MARKETING_SITE_URL}/for-payers-and-providers`}
                  target="_blank"
                  className="nav-link type-2 w-nav-link w--nav-link-open"
                  rel="noreferrer"
                >
                  For Payers & Providers
                </a>
                <a
                  href={`${process.env.REACT_APP_MARKETING_SITE_URL}/news`}
                  target="_blank"
                  className="nav-link type-2 w-nav-link w--nav-link-open"
                  rel="noreferrer"
                >
                  News & Literature
                </a>
                <a
                  href={`${process.env.REACT_APP_MARKETING_SITE_URL}/about`}
                  target="_blank"
                  aria-current="page"
                  className="nav-link type-2 w-nav-link w--nav-link-open"
                  rel="noreferrer"
                >
                  About
                </a>
                <a
                  href={`${process.env.REACT_APP_MARKETING_SITE_URL}/contact`}
                  target="_blank"
                  className="nav-link button-style-1 nav-bar w-button"
                  aria-label="home"
                  rel="noreferrer"
                >
                  Get in touch
                </a>
              </div>
            </nav>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
