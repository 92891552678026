import Rollbar, { Level, LogArgument } from 'rollbar';

export interface RollbarPerson {
  id: number; //required
  username?: string;
  email?: string;
}

export class RollbarLogger {
  static rollbar: Rollbar;
  static shouldLogToRollbar: boolean;
  static shouldLogToConsole: boolean;
  static additionalInfo: any;

  static init(rollbar: Rollbar) {
    this.rollbar = rollbar;
    return this;
  }

  static debug(message: string, extraData?: LogArgument) {
    this.logToRollbar('debug', null, message, extraData);
  }

  static info(message: string, extraData?: LogArgument) {
    this.logToRollbar('info', null, message, extraData);
  }

  static error(error: Error, message: string, extraData?: LogArgument) {
    this.logToRollbar('error', error, message, extraData);
  }

  static critical(message: string, extraData?: LogArgument) {
    this.logToRollbar('critical', null, message, extraData);
  }

  static warning(message: string, extraData?: LogArgument) {
    this.logToRollbar('warning', null, message, extraData);
  }

  //   Use this method to set other properties to be sent in logs (Eg: UserId, email or any other debug object)
  //   Only add global values here as these will persist. If it's any local info pertaining to a single entity pass that as part of the extraData param to your logs
  static addAdditionalInfo(key: string, value: any) {
    this.additionalInfo[key] = value;
  }

  static setRollbarUser(user: RollbarPerson) {
    this.rollbar.configure({ payload: { person: user } });
  }

  private static logToRollbar(
    logLevel: Level,
    error?: any,
    message?: string,
    extraData?: LogArgument
  ) {
    const finalExtraData = {
      ...this.additionalInfo,
      ...{ otherInfo: extraData },
    };

    this.shouldLogToConsole &&
      console.log(
        message,
        error ? JSON.stringify(error) : {},
        JSON.stringify(finalExtraData)
      );

    if (this.shouldLogToRollbar) {
      switch (logLevel) {
      case 'debug':
        this.rollbar.debug(message, finalExtraData);
        break;
      case 'info':
        this.rollbar.info(message, finalExtraData);
        break;
      case 'error':
        this.rollbar.error(message, error, finalExtraData);
        break;
      case 'critical':
        this.rollbar.critical(message, finalExtraData);
        break;
      case 'warning':
        this.rollbar.info(message, finalExtraData);
        break;
      default:
        console.log(message, JSON.stringify(error));
      }
    }
    return;
  }
}
