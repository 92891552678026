import React, { useEffect, useState } from 'react';

import { Circle } from '@mui/icons-material';
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Unstable_Grid2 as Grid2,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { DateCalendar, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { PotentialAppointmentSlot } from '@wellinks/wellinks-healthie/types/src/generated/graphql';
import dayjs, { Dayjs } from 'dayjs';
import { isMobile } from 'react-device-detect';
import { loader } from 'react-global-loader';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import SignupHeader from './SignupHeader';
import { saveMemberSignupHistory } from '../../api/EnrollmentApi';
import {
  bookAppointment,
  getAvailableAppointmentDaysByMonth,
  getAvailableAppointmentTimes,
  updateHealthiePatientProvider,
} from '../../api/HealthieAPI';
import {
  associateHelpScoutConversation,
  createHelpScoutConversationTags,
  createHelpScoutCustomer,
  createHelpScoutNote,
  helpScoutProcess,
} from '../../api/HelpScoutApi';
import { Logger } from '../../App';
import {
  getHealthieDayJSDate,
  getUserFormattedTimeZone,
  getUserIANATimeZone,
} from '../../helpers/dateHelper';
import useNavBlocker from '../../hooks/useNavBlocker';
import {
  AppointmentBookingRequest,
  CategorizedTimes,
  ExclusionTimes,
} from '../../interfaces/Healthie';
import { HelpScoutCustomer } from '../../interfaces/HelpScout';
import { MemberInformation } from '../../interfaces/MemberInformation';
import { Store } from '../../store/context';
import {
  addHealthieScheduling,
  updateHelpScoutCustomerID,
} from '../../store/ext/actions';
import Avatar from '../UIComponents/Avatar';
import BeaconButton from '../UIComponents/BeaconButton/BeaconButton';
import ErrorBox from '../UIComponents/ErrorBox';
import * as Icons from '../UIComponents/VectorIcons';

function SetupFirstAppointment() {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const isSmallerDisplay = useMediaQuery(useTheme().breakpoints.down('lg'));

  const pageTitle = t('setupFirstAppointment.pageTitle');
  const pageDescription = t('setupFirstAppointment.pageDescription');

  const {
    contactInfoState,
    credentialsInfoState,
    providerInfoState,
    extState,
    extDispatch,
  } = React.useContext(Store);

  const [selectableDays, setSelectableDays] = useState<string[]>([]);
  const [categorizedTimes, setCategorizedTimes] =
    useState<CategorizedTimes | null>(null);
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(null);
  const [selectedAppointmentSlot, setSelectedAppointmentSlot] =
    useState<PotentialAppointmentSlot | null>(null);
  const [excludedTimes, setExcludedTimes] = useState<ExclusionTimes>({});
  const [
    selectedMonthInitialLoadComplete,
    setSelectedMonthInitialLoadComplete,
  ] = useState(false);
  const [selectedDayLoadComplete, setSelectedDayLoadComplete] = useState(false);

  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [errorModalTitle, setErrorModalTitle] = useState('');
  const [errorModalDescription, setErrorModalDescription] = useState('');

  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);

  const userTimezone = getUserIANATimeZone();
  const conferenceTypes = {
    phone: 'Phone Call',
    video: process.env.REACT_APP_HEALTHIE_VIDEO_CALL_TYPE as string,
  };

  // const theme = useTheme();
  // const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const contactType = contactInfoState.preferPhoneCall
    ? conferenceTypes.phone
    : conferenceTypes.video;

  useNavBlocker('Changes you made may not be saved.');

  useEffect(() => {
    if (!extState.Healthie.userID) {
      // TODO: what happens if we don't have this, seems like we should bail??
    }

    getAvailableDatesByMonth(
      dayjs(new Date()).format('YYYY-MM-DD'),
      userTimezone
    );
  }, []);

  useEffect(() => {
    if (extState.Healthie.scheduling) {
      setConfirmationModalOpen(true);
    }
  }, [extState.Healthie]);

  useEffect(() => {
    if (selectedDate) {
      getAvailableAppointmentsByDate(selectedDate);
    }
  }, [selectedDate]);

  useEffect(() => {
    let isMounted = true; //cancel async call if unmounted early

    const logAccountCreated = async () => {
      await createHelpScoutConversationTags(
        extState.HelpScout.conversationID,
        helpScoutProcess.accountCreated.tags
      );

      const memberInfo: MemberInformation = providerInfoState.memberInformation;

      const helpScoutCustomer: HelpScoutCustomer =
        memberInfo.primaryPolicyHolder
          ? {
            firstName:
                memberInfo.insuredPreferredName || memberInfo.insuredFirstName,
            lastName: memberInfo.insuredLastName,
            email: credentialsInfoState.credentialsInformation.email,
          }
          : {
            firstName: memberInfo.preferredName || memberInfo.firstName,
            lastName: memberInfo.lastName,
            email: credentialsInfoState.credentialsInformation.email,
          };

      const helpScoutCustomerID = await createHelpScoutCustomer(
        helpScoutCustomer
      );

      if (isMounted && helpScoutCustomerID) {
        extDispatch(updateHelpScoutCustomerID(helpScoutCustomerID));
        const conversationAssociated = await associateHelpScoutConversation(
          extState.HelpScout.conversationID,
          helpScoutCustomerID
        );

        if (conversationAssociated) {
          createHelpScoutNote(
            extState.HelpScout.conversationID,
            helpScoutProcess.accountCreated.buildNote(
              credentialsInfoState.credentialsInformation.email,
              extState,
              memberInfo
            )
          );
        }
      }
    };

    //verify that the hubble creation was attempted and customerID does not exist (if navigating back forth in the form)
    if (
      extState.Wellinks.accountCreationAttempted &&
      extState.HelpScout.conversationID &&
      !extState.HelpScout.customerID
    ) {
      logAccountCreated();
    }

    return () => {
      isMounted = false;
    };
  }, [extState.Wellinks.accountCreationAttempted]);

  const createKickoffScheduledHistory = async () => {
    try {
      await saveMemberSignupHistory({
        healthie_api_token: extState.Healthie.token,
        healthie_id: extState.Healthie.userID,
        member_id: extState.Wellinks.memberId,
        contracts_patients_id: extState.Wellinks.contractsPatientsID,
        signup_status: 'KICKOFF_SCHEDULED',
      });
    } catch (error) {
      // eat error since this is not critical
      // the implementation of saveMemberSignupHistory logs to rollbar already
    }
  };

  const getAvailableDatesByMonth = async (
    monthStartDate: string,
    userTimezone: string
  ) => {
    loader.show();
    try {
      const availableDates = await getAvailableAppointmentDaysByMonth(
        process.env.REACT_APP_HEALTHIE_DEV_PROVIDER_ID as string,
        monthStartDate,
        process.env.REACT_APP_HEALTHIE_APPOINTMENT_TYPE_ID as string,
        userTimezone
      );
      const { daysAvailableForRange } = availableDates;
      setSelectableDays(
        daysAvailableForRange
          ? (availableDates.daysAvailableForRange as string[])
          : []
      );
      setSelectedMonthInitialLoadComplete(true);
      const firstAvailableDate = daysAvailableForRange?.[0];

      if (firstAvailableDate && !selectedDate) {
        setSelectedDate(dayjs(firstAvailableDate));
      }
      loader.hide();
    } catch (e) {
      loader.hide();
      setErrorModalTitle('setupFirstAppointment.modalErrorLoadingDatesTitle');
      setErrorModalDescription(
        'setupFirstAppointment.modalErrorLoadingDatesDescription'
      );
      setErrorModalOpen(true);
      Logger.error(
        e as Error,
        'Error gettin Available Appointment Days By Month.',
        'Setup First Appointment page.'
      );
    }
  };

  const getAvailableAppointmentsByDate = async (date: Dayjs) => {
    setCategorizedTimes(null);
    setSelectedDayLoadComplete(false);
    try {
      loader.show();
      const availableTimes = await getAvailableAppointmentTimes(
        process.env.REACT_APP_HEALTHIE_DEV_PROVIDER_ID as string,
        date.format('YYYY-MM-DD'),
        process.env.REACT_APP_HEALTHIE_APPOINTMENT_TYPE_ID as string,
        userTimezone
      );

      if (
        availableTimes?.availableSlotsForRange &&
        availableTimes?.availableSlotsForRange.length > 0
      ) {
        setCategorizedTimes(
          categorizeAppointmentSlots(
            availableTimes?.availableSlotsForRange as PotentialAppointmentSlot[]
          )
        );
      }
      loader.hide();
    } catch (e) {
      loader.hide();
      setErrorModalTitle('setupFirstAppointment.modalErrorLoadingTimesTitle');
      setErrorModalDescription(
        'setupFirstAppointment.modalErrorLoadingTimesDescription'
      );
      setErrorModalOpen(true);
      Logger.error(
        e as Error,
        'Error gettin Available Appointment Days By Date.',
        'Setup First Appointment page.'
      );
    } finally {
      setSelectedDayLoadComplete(true);
    }
  };

  const handleDateChange = (date: Dayjs | null) => {
    setSelectedDate(date);
    setSelectedAppointmentSlot(null);
  };

  const icsHealthiePathBuilder = (
    appointmentID: string,
    appointmentTypeID: string,
    calendarType: string
  ) => {
    return `${
      process.env.REACT_APP_HEALTHIE_API_URL
    }/appointments/add_to_cal/${appointmentID}.ics?cal_type=${calendarType}&cal_export_id=${btoa(
      `${appointmentID}:${appointmentTypeID}`
    )}`;
  };

  const categorizeAppointmentSlots = (
    selectableTimes: any
  ): CategorizedTimes => {
    const categorizedTimes: CategorizedTimes = {
      morning: [],
      afternoon: [],
      evening: [],
    };

    selectableTimes.forEach((slot: PotentialAppointmentSlot) => {
      const hour = parseInt(getHealthieDayJSDate(slot.date).format('H'), 10); //24 hour scale
      if (hour < 12) {
        categorizedTimes.morning.push(slot);
      } else if (hour >= 12 && hour <= 17) {
        categorizedTimes.afternoon.push(slot);
      } else {
        categorizedTimes.evening.push(slot);
      }
    });
    return categorizedTimes;
  };

  const bookHealthieAppointment = async (
    selectedAppointmentSlot: PotentialAppointmentSlot | null
  ) => {
    const request: AppointmentBookingRequest = {
      email: credentialsInfoState.credentialsInformation.email,
      phone_number: contactInfoState.phoneNumber,
      contact_type: contactType,
      timezone: userTimezone,
      appointment_id: null,
      provider_id: selectedAppointmentSlot?.user_id as string,
      date: selectedAppointmentSlot?.date as string,
      appointment_type_id: process.env
        .REACT_APP_HEALTHIE_APPOINTMENT_TYPE_ID as string,
    };

    loader.show();
    try {
      const appointmentBookingResult = await bookAppointment(request);
      if (appointmentBookingResult?.completeCheckout?.appointment?.provider) {
        extDispatch(addHealthieScheduling(appointmentBookingResult));
        loader.hide();
        updateHealthiePatientProvider(
          extState.Healthie.userID,
          appointmentBookingResult.completeCheckout.appointment.provider?.id
        );
        createHelpScoutNote(
          extState.HelpScout.conversationID,
          helpScoutProcess.kickOffScheduled.buildNote(
            credentialsInfoState.credentialsInformation.email,
            appointmentBookingResult
          )
        );
        createHelpScoutConversationTags(
          extState.HelpScout.conversationID,
          helpScoutProcess.kickOffScheduled.tags
        );

        createKickoffScheduledHistory();
      } else {
        if (
          appointmentBookingResult?.completeCheckout?.messages &&
          appointmentBookingResult?.completeCheckout?.messages[0]
        ) {
          const errorMessage =
            appointmentBookingResult.completeCheckout.messages[0]?.message;

          setErrorModalTitle('setupFirstAppointment.modalErrorBookingTitle');
          setErrorModalDescription(errorMessage);

          if (
            errorMessage ==
              'Time is no longer available. Please select a new time.' &&
            selectedAppointmentSlot?.date
          ) {
            setExcludedTimes({
              ...excludedTimes,
              [selectedAppointmentSlot.date]: selectedAppointmentSlot.date,
            });
            setSelectedAppointmentSlot(null);
            if (selectedDate) {
              getAvailableAppointmentsByDate(selectedDate);
            }
          }
          loader.hide();
          setErrorModalOpen(true);
        }
      }
    } catch (e) {
      loader.hide();
      setErrorModalTitle('setupFirstAppointment.modalErrorBookingTitle');
      setErrorModalDescription(
        'setupFirstAppointment.modalErrorBookingDescription'
      );
      setErrorModalOpen(true);
      Logger.error(
        e as Error,
        'Error booking Appointment',
        'Setup First Appointment page.'
      );
    }
  };

  const renderNoDatesAvailable = () => {
    return (
      <Grid2 sm={12} textAlign={'center'}>
        <div className="apptPickerTitle">
          <strong>
            {t('setupFirstAppointment.appointmentPickerNoDatesAvailableTitle')}
          </strong>
        </div>
        <div className="apptPickerDescription">
          <strong>
            {t(
              'setupFirstAppointment.appointmentPickerNoDatesAvailableDescription'
            )}
          </strong>
        </div>
      </Grid2>
    );
  };

  const renderNoTimesAvailable = () => {
    return (
      <Grid2 sm={12} textAlign={'center'}>
        <div className="apptPickerTitle">
          <strong>
            {t('setupFirstAppointment.appointmentPickerNoTimesAvailableTitle')}
          </strong>
        </div>
        <div className="apptPickerDescription">
          <strong>
            {t(
              'setupFirstAppointment.appointmentPickerNoTimesAvailableDescription'
            )}
          </strong>
        </div>
      </Grid2>
    );
  };

  const scheduling = extState.Healthie?.scheduling?.completeCheckout;

  return (
    <>
      <SignupHeader
        title={pageTitle}
        description={pageDescription}
        activeStepNumber={3}
      />
      <Grid2 container rowSpacing={1} disableEqualOverflow>
        <Grid2 xs={12} sm={12} maxWidth={1000} margin={'auto'}>
          <Grid2 container>
            <Grid2 xs={12} textAlign={'right'} paddingX={1}>
              <BeaconButton />
            </Grid2>
            <Grid2
              xs={12}
              lg={4}
              xl={4}
              lgOffset={2}
              xlOffset={2}
              marginTop={1}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateCalendar
                  disablePast
                  dayOfWeekFormatter={(day) => {
                    return day;
                  }}
                  className="wl-date-picker"
                  views={['day']}
                  sx={{ marginRight: isSmallerDisplay ? '' : '14px' }}
                  value={selectedDate}
                  onMonthChange={async (date) => {
                    getAvailableDatesByMonth(date.toString(), userTimezone);
                  }}
                  onChange={handleDateChange}
                  shouldDisableDate={(date) => {
                    return !selectableDays.includes(
                      dayjs(date).format('YYYY-MM-DD')
                    );
                  }}
                />
              </LocalizationProvider>
              <div className="datePickerLegendItem">
                {t('setupFirstAppointment.datePickerLegendAvailable')} ={' '}
                <Circle style={{ color: '#AEC5DC' }} />
              </div>
              <div className="datePickerLegendItem">
                {t('setupFirstAppointment.datePickerLegendSelected')} ={' '}
                <Circle style={{ color: '#3A66FF' }} />
              </div>
            </Grid2>
            <Grid2
              xs={12}
              md={8}
              lg={6}
              xl={6}
              mdOffset={2}
              lgOffset={0}
              padding={1}
              paddingX={0}
              marginTop={1}
            >
              <div
                className="appointmentDateContainer"
                style={{ marginLeft: isSmallerDisplay ? 'auto' : 0 }}
              >
                <Grid2 container>
                  {!categorizedTimes ? (
                    <>
                      {selectedMonthInitialLoadComplete &&
                        !selectedDate &&
                        renderNoDatesAvailable()}
                      {selectedDayLoadComplete && renderNoTimesAvailable()}
                    </>
                  ) : (
                    <Grid2 sm={9} smOffset={3} textAlign="left" padding={0}>
                      <div className="apptPickerTitle">
                        <strong>
                          {selectedDate && dayjs(selectedDate).format('MMMM D')}
                        </strong>
                      </div>
                      <div className="apptPickerDescription">
                        <strong>
                          {t(
                            'setupFirstAppointment.appointmentPickerDescription'
                          )}
                        </strong>
                      </div>
                      <div className="apptPickerDescription">
                        {t('setupFirstAppointment.userTimezoneDescription')}:{' '}
                        {getUserFormattedTimeZone()}
                      </div>
                    </Grid2>
                  )}
                  {categorizedTimes &&
                    Object.entries(categorizedTimes).map(([key, values]) => {
                      if (values.length > 0) {
                        return (
                          <React.Fragment key={key}>
                            <Grid2 xs={12} sm={3} textAlign="left" paddingY={1}>
                              <div className="apptPickerTimeOfDay">
                                {t(`setupFirstAppointment.${key}TimeOfDay`)}
                              </div>
                            </Grid2>
                            <Grid2 xs={12} sm={9} textAlign="left" paddingX={0}>
                              {values?.map(
                                (s: PotentialAppointmentSlot, idx: number) =>
                                  s.date &&
                                  !excludedTimes[s.date] && (
                                    <Chip
                                      clickable
                                      onClick={() => {
                                        setSelectedAppointmentSlot(s || null);
                                      }}
                                      size={'medium'}
                                      label={getHealthieDayJSDate(
                                        s.date
                                      ).format('h:mm a')}
                                      key={idx}
                                      className={[
                                        selectedAppointmentSlot == s
                                          ? 'apptSelectedChip'
                                          : '',
                                        'apptPickerChip',
                                      ].join(' ')}
                                    />
                                  )
                              )}
                            </Grid2>
                          </React.Fragment>
                        );
                      }
                    })}
                </Grid2>
                <div
                  className={
                    isSmallerDisplay
                      ? 'appointmentDateContainerTailTop'
                      : 'appointmentDateContainerTail'
                  }
                />
              </div>
              <Grid2 xs={12} textAlign={'right'} marginTop={1} paddingX={1}>
                <Button
                  id="schedule-session-button"
                  variant="text"
                  onClick={() =>
                    bookHealthieAppointment(selectedAppointmentSlot)
                  }
                  disabled={!selectedAppointmentSlot}
                  className={'wl-button'}
                  type="submit"
                >
                  {!selectedAppointmentSlot
                    ? t('setupFirstAppointment.selectATimeBtn')
                    : t('setupFirstAppointment.scheduleAppointmentBtn')}
                </Button>
                {!selectedAppointmentSlot && (
                  <div className="apptPickerInfo">
                    <ErrorBox
                      errorMessage={t('setupFirstAppointment.chooseADateHint')}
                      hasErrored={false}
                    />
                  </div>
                )}
              </Grid2>
            </Grid2>
          </Grid2>
        </Grid2>
      </Grid2>
      <Dialog
        id="appointmentConfirmationModal"
        open={confirmationModalOpen}
        maxWidth="xs"
        fullScreen={isMobile}
        sx={{ minWidth: 320, margin: 0 }}
        aria-labelledby="appointmentConfirmationModal"
      >
        <DialogContent>
          <Box className="wl-modal-title" marginBottom={2}>
            <Grid2 container rowSpacing={1}>
              <Grid2 xs={12} display="flex" justifyContent={'center'}>
                <Box display="flex" alignItems="flex-end">
                  <Avatar
                    imageURL={scheduling?.appointment?.provider?.avatar_url}
                    fullName={scheduling?.appointment?.provider?.full_name}
                    height={100}
                    width={100}
                    alternateString={t(
                      'setupFirstAppointment.modalAppointmentConfirmed.profilePic'
                    )}
                  />
                  <Icons.check_circle
                    width={30}
                    height={30}
                    style={{ marginLeft: -25 }}
                  />
                </Box>
              </Grid2>
              <Grid2 xs={9} md={8} xsOffset={1.5} mdOffset={2}>
                <h1 className="wl-modal-title">
                  {t('setupFirstAppointment.modalAppointmentConfirmed.title')}{' '}
                  {scheduling?.appointment?.provider?.full_name}!
                </h1>
              </Grid2>
            </Grid2>
          </Box>
          <Grid2 container>
            <Grid2 xs={12} display={'grid'} justifyContent={'center'}>
              <Box>
                <Box className="wl-modal-subtitle">
                  {t(
                    'setupFirstAppointment.modalAppointmentConfirmed.description'
                  )}
                </Box>
                <Box id="scheduled-date" className="schedulingRow">
                  <Icons.calendar className="schedulingIcon" />{' '}
                  {getHealthieDayJSDate(scheduling?.appointment?.date).format(
                    'dddd MMMM D, YYYY'
                  )}
                </Box>
                <Box id="scheduled-time" className="schedulingRow">
                  <Icons.clock className="schedulingIcon" />
                  {`${getHealthieDayJSDate(
                    scheduling?.appointment?.start
                  ).format('h:mm')} - ${getHealthieDayJSDate(
                    scheduling?.appointment?.end
                  ).format('h:mm a')}`}{' '}
                  ({getUserFormattedTimeZone()})
                </Box>
              </Box>
              <Box id="appointment-meeting-type" className="schedulingRow">
                {contactType == conferenceTypes.video ? (
                  <Icons.video className="schedulingIcon" />
                ) : (
                  <Icons.phone className="schedulingIcon" />
                )}
                {contactType == conferenceTypes.video
                  ? t(
                    `setupFirstAppointment.modalAppointmentConfirmed.zoomVideoCall`
                  )
                  : `${t(
                    'setupFirstAppointment.modalAppointmentConfirmed.phoneCall'
                  )} to ${contactInfoState.phoneNumber}`}
              </Box>
              <Box className="schedulingContainer">
                <Link
                  id="google-calendar-link"
                  to={scheduling?.appointment?.add_to_gcal_link}
                  target="_blank"
                  className="schedulingRow"
                >
                  <Icons.calendar_google className="calendarIcon" />
                  {t(
                    'setupFirstAppointment.modalAppointmentConfirmed.googleCalendar'
                  )}{' '}
                </Link>
                <Link
                  id="outlook-calendar-link"
                  to={icsHealthiePathBuilder(
                    scheduling?.appointment?.id,
                    scheduling?.appointment?.appointment_type?.id,
                    'outlook'
                  )}
                  target="_blank"
                  className="schedulingRow"
                >
                  <Icons.calendar_outlook className="calendarIcon" />
                  {t(
                    'setupFirstAppointment.modalAppointmentConfirmed.outlookCalendar'
                  )}
                </Link>
                <Link
                  id="apple-calendar-link"
                  to={icsHealthiePathBuilder(
                    scheduling?.appointment?.id,
                    scheduling?.appointment?.appointment_type?.id,
                    'ical'
                  )}
                  target="_blank"
                  className="schedulingRow"
                >
                  <Icons.calendar_apple className="calendarIcon" />
                  {t(
                    'setupFirstAppointment.modalAppointmentConfirmed.appleCalendar'
                  )}
                </Link>
              </Box>
            </Grid2>
          </Grid2>
          <Box className="schedulingContainer" textAlign={'center'} marginX={2}>
            {t('setupFirstAppointment.modalAppointmentConfirmed.footer')}
          </Box>
        </DialogContent>
        <Box textAlign={'center'} position={'sticky'} marginTop={2}>
          <Button
            id="next-button"
            className="wl-button btn-secondary"
            variant="contained"
            onClick={() => {
              navigate(
                {
                  pathname: '/congratulations',
                  search: location.search,
                },
                { replace: true }
              );
            }}
          >
            <Trans i18nKey="setupFirstAppointment.modalAppointmentConfirmed.nextButton" />
          </Button>
        </Box>
      </Dialog>
      <Dialog open={errorModalOpen}>
        <DialogTitle className="wl-modal-title">
          <Trans i18nKey={errorModalTitle} />
        </DialogTitle>
        <DialogContent>
          <Box whiteSpace={'pre-line'}>
            <Trans i18nKey={errorModalDescription} />
          </Box>
          <Box textAlign={'center'} marginTop={2}>
            <Button
              className="wl-button btn-secondary"
              variant="contained"
              onClick={() => {
                setErrorModalOpen(false);
              }}
            >
              <Trans i18nKey="setupFirstAppointment.modalButtonRetry" />
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default SetupFirstAppointment;
