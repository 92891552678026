import React from 'react';

import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import { ErrorBoundary, Provider, RollbarContext } from '@rollbar/react';
import { LoaderContainer } from 'react-global-loader';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Rollbar from 'rollbar';
import './App.scss';

import Loader from './components/Loader/Loader';
import { RollbarLogger } from './logger/Logger';
import ErrorPage from './pages/Error';
import RootLayout from './pages/RootLayout';
import ProviderInfoPage from './pages/SignUpWorkflow/P1-CollectProviderInfo';
import ContactPage from './pages/SignUpWorkflow/P2-CollectContactInfo';
import IneligiblePage from './pages/SignUpWorkflow/P2-Ineligible';
import CredentialsPage from './pages/SignUpWorkflow/P3-CollectCredentials';
import FirstAppointmentPage from './pages/SignUpWorkflow/P4-SetupFirstAppointment';
import ConfirmationPage from './pages/SignUpWorkflow/P5-Confirmation';
import packageInfo from '../package.json';

const router = createBrowserRouter([
  {
    path: '/',
    element: <RootLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: (
          <RollbarContext context="/providerInfo">
            <ProviderInfoPage />
          </RollbarContext>
        ),
      },
      {
        path: 'ineligible',
        element: (
          <RollbarContext context="/ineligible">
            <IneligiblePage />
          </RollbarContext>
        ),
      },
      {
        path: 'contactInfo',
        element: (
          <RollbarContext context="/contactInfo">
            <ContactPage />
          </RollbarContext>
        ),
      },
      {
        path: 'credentials',
        element: (
          <RollbarContext context="/credentialsInfo">
            <CredentialsPage />
          </RollbarContext>
        ),
      },
      {
        path: 'setupFirstAppointment',
        element: (
          <RollbarContext context="/firstAppointment">
            <FirstAppointmentPage />
          </RollbarContext>
        ),
      },
      {
        path: 'congratulations',
        element: (
          <RollbarContext context="/signupConfirmation">
            <ConfirmationPage />
          </RollbarContext>
        ),
      },
    ],
  },
  { path: 'error', element: <ErrorPage /> },
]);

// ###### Rollbar and Logging properties ######
//  *********** Set this to true if logging is needed for DEV as well. ***********
const shouldLogToRollbar = process.env.NODE_ENV != 'development' ? true : false;
// Set this to false to disable console logs.
const shouldLogToConsole = true;

const rollbarConfig = {
  accessToken: `${process.env.REACT_APP_ROLLBAR_TOKEN}`,
  hostSafeList: ['localhost:3000', 'localhost:4000'],
  captureUncaught: true,
  captureUnhandledRejections: true,
  enabled: shouldLogToRollbar,
  payload: {
    environment: `${process.env.NODE_ENV}`,
    client: {
      javascript: {
        code_version: `${packageInfo.version}`,
        source_map_enabled: true,
      },
    },
  },
};

const rollbar = new Rollbar(rollbarConfig);
const logManager = RollbarLogger.init(rollbar);
logManager.shouldLogToRollbar = shouldLogToRollbar;
logManager.shouldLogToConsole = shouldLogToConsole;

const light = createTheme({
  spacing: 10,
  typography: {
    button: {
      textTransform: 'none',
    },
  },
  palette: {
    mode: 'light',
    primary: {
      main: '#0A494E',
    },
    secondary: {
      main: '#3A66FF',
    },
    background: {
      default: '#FAFAFC',
    },
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: '#FFFFFF',
        },
      },
    },
  },
});

class App extends React.Component {
  render() {
    return (
      <Provider instance={rollbar}>
        <ErrorBoundary>
          <ThemeProvider theme={light}>
            <CssBaseline>
              <LoaderContainer>
                <Loader />
              </LoaderContainer>
              <RouterProvider router={router} />
            </CssBaseline>
          </ThemeProvider>
        </ErrorBoundary>
      </Provider>
    );
  }
}

export { logManager as Logger };
export default App;
