import { CompleteCheckoutMutation } from '@wellinks/wellinks-healthie/types/src/generated/graphql';

export const UPDATE_HELP_SCOUT_CONVERSATION = 'UPDATE_HELP_SCOUT_CONVERSATION';
export const UPDATE_HELP_SCOUT_CUSTOMER = 'UPDATE_HELP_SCOUT_CUSTOMER';
export const UPDATE_WELLINKS_INFO = 'UPDATE_WELLINKS_INFO';
export const UPDATE_HEALTHIE_STORE = 'UPDATE_HEALTHIE_STORE';
export const ADD_HEALTHIE_SCHEDULING = 'ADD_HEALTHIE_SCHEDULING';

export function updateHelpScoutConversationID(helpScoutConversationID: number) {
  return {
    type: UPDATE_HELP_SCOUT_CONVERSATION,
    payload: helpScoutConversationID,
  };
}

export function updateHelpScoutCustomerID(helpScoutCustomerID: number) {
  return {
    type: UPDATE_HELP_SCOUT_CUSTOMER,
    payload: helpScoutCustomerID,
  };
}

export function updateWellinksInfo(
  member_id: string,
  wellinks_member_id: string,
  contracts_patients_id: string,
  contract_name: string
) {
  return {
    type: UPDATE_WELLINKS_INFO,
    payload: {
      member_id,
      wellinks_member_id,
      contracts_patients_id,
      contract_name,
    },
  };
}

export function addHealthieScheduling(scheduling: CompleteCheckoutMutation) {
  return {
    type: ADD_HEALTHIE_SCHEDULING,
    payload: { scheduling },
  };
}

export function updateHealthieStore(userID: string, token: string) {
  return {
    type: UPDATE_HEALTHIE_STORE,
    payload: { userID, token },
  };
}
