import React, { useEffect, useState } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Autocomplete,
  Box,
  Button,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  InputAdornment,
  TextField,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Formik } from 'formik';
import i18next from 'i18next';
import { loader } from 'react-global-loader';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import styles from './Signup.module.scss';
import SignupHeader from './SignupHeader';
import { getStates, saveLead } from '../../api/EnrollmentApi';
import {
  createHelpScoutConversation,
  helpScoutProcess,
} from '../../api/HelpScoutApi';
import { Logger } from '../../App';
import { handleKeyDown, scrollToTop } from '../../helpers/pageHelpers';
import { IneligibleMemberInfo } from '../../interfaces/IneligibleMemberInfo';
import { SaveLeadRequest } from '../../interfaces/Lead';
import { Store } from '../../store/context';
import { updateHelpScoutConversationID } from '../../store/ext/actions';
import BeaconButton from '../UIComponents/BeaconButton/BeaconButton';
import ErrorBox from '../UIComponents/ErrorBox';
import * as Icons from '../UIComponents/VectorIcons';

function CollectIneligibleInfo() {
  const { t } = useTranslation();
  const pageTitle = t('ineligible.pageTitle');
  const pageDescription = t('ineligible.pageDescription');

  const { providerInfoState, providerInfoStateDispatch } =
    React.useContext(Store);
  const [isSubmitting, setSubmitting] = useState(false);
  const [submitAttempted, setSubmitAttempted] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [usStatesList, setUsStatesList] = useState<string[]>([]);
  const [leadSaved, setLeadSaved] = useState(false);
  const affiliate = providerInfoState.memberInformation.enrollmentAffiliate;
  const emailRegex = new RegExp(
    /^[\w+-.]+@[a-z\d-]+(\.[a-z\d-]+)*\.[a-z]+$/,
    'i'
  );
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const emailPlaceholder = t('collectCreds.emailPlaceholder');

  const urlParams = new URLSearchParams(location.search);

  const initialFormValues = {
    enrollmentAffiliate: affiliate,
    email: '',
    state: null,
  };

  const { contactInfoState, extDispatch } = React.useContext(Store);
  useEffect(() => {
    loader.show();
    async function fetchStates() {
      try {
        const states = await getStates();
        setUsStatesList(states.map((state) => state.name));
        loader.hide();
      } catch (error) {
        loader.hide();
        setErrorModalOpen(true);
        Logger.error(
          error as Error,
          'Failed to get a list of states.',
          'Collect Ineligible Info page.'
        );
      }
    }

    fetchStates();
  }, []);

  const createLeadRequest = (values: IneligibleMemberInfo) => {
    const memberInfo = providerInfoState.memberInformation;
    const urlMedium = urlParams.get('medium');
    const urlSource = urlParams.get('source');
    const urlCampaign = urlParams.get('campaign');
    const urlContent = urlParams.get('content');

    const leadReq: SaveLeadRequest = {
      first_name: memberInfo.primaryPolicyHolder
        ? memberInfo.insuredFirstName
        : memberInfo.firstName,
      last_name: memberInfo.primaryPolicyHolder
        ? memberInfo.insuredLastName
        : memberInfo.lastName,
      email: values.email,
      date_of_birth: memberInfo.primaryPolicyHolder
        ? memberInfo.insuredDateOfBirth
        : memberInfo.dateOfBirth,
      state_name: values.state || '',
      enrollment_affiliate_id: values.enrollmentAffiliate?.id || '',
      gender_description: memberInfo.genderName,
    };

    const prefName = memberInfo.primaryPolicyHolder
      ? memberInfo.insuredPreferredName
      : memberInfo.preferredName;
    if (prefName) {
      leadReq['preferred_name'] = prefName;
    }

    if (memberInfo.selfDescribedGender) {
      leadReq['self_described_gender'] = memberInfo.selfDescribedGender;
    }

    if (memberInfo.referralSource.mailReferral) {
      leadReq['mail_referral'] = memberInfo.referralSource.mailReferral;
    }

    if (memberInfo.referralSource.emailReferral) {
      leadReq['email_referral'] = memberInfo.referralSource.emailReferral;
    }

    if (memberInfo.referralSource.phoneReferral) {
      leadReq['phone_referral'] = memberInfo.referralSource.phoneReferral;
    }

    if (memberInfo.referralSource.providerReferral) {
      leadReq['provider_referral'] = memberInfo.referralSource.providerReferral;
    }

    if (memberInfo.referralSource.otherReferral) {
      leadReq['other_referral'] = memberInfo.referralSource.otherReferral;
    }

    if (memberInfo.providerReferralSource) {
      leadReq['provider_name'] = memberInfo.providerReferralSource;
    }

    if (memberInfo.otherReferralSource) {
      leadReq['other_referral_info'] = memberInfo.otherReferralSource;
    }

    if (urlMedium) {
      leadReq['url_referral_medium'] = urlMedium;
    }

    if (urlSource) {
      leadReq['url_referral_source'] = urlSource;
    }

    if (urlCampaign) {
      leadReq['url_referral_campaign'] = urlCampaign;
    }

    if (urlContent) {
      leadReq['url_referral_content'] = urlContent;
    }

    return leadReq;
  };

  const createInitialHelpScoutConversation = async (
    values: IneligibleMemberInfo
  ) => {
    const helpScoutConversationResponse = await createHelpScoutConversation(
      helpScoutProcess.verifyIneligibility.buildHelpScoutConversation(
        providerInfoState.memberInformation,
        values
      )
    );

    if (helpScoutConversationResponse.conversationID) {
      extDispatch(
        updateHelpScoutConversationID(
          helpScoutConversationResponse.conversationID
        )
      );
    }
  };

  const submitAndNavigate = async (values: IneligibleMemberInfo) => {
    loader.show();
    const req = createLeadRequest(values);

    try {
      const leadID = await saveLead(req);

      loader.hide();

      if (leadID) {
        setLeadSaved(true);
        await createInitialHelpScoutConversation(values);
      }
    } catch (error) {
      loader.hide();
      setErrorModalOpen(true);
      Logger.error(error as Error, 'Failed to Save Lead - Ineligible page.', {
        unsavedLead: req,
      });
      return;
    }
  };

  const ineligibleValidationSchema = yup.object().shape({
    email: yup
      .string()
      .matches(emailRegex, `${t('collectCreds.invalidEmailError')}`)
      .required(`${t('collectCreds.emailRequired')}`),
    state: yup.string().required(`${t('contactInfo.stateRequired')}`),
  });

  return (
    <div>
      <SignupHeader
        title={pageTitle}
        description={pageDescription}
        activeStepNumber={0}
      />
      <Formik
        initialValues={initialFormValues}
        enableReinitialize={true}
        validationSchema={ineligibleValidationSchema}
        validateOnChange={true}
        validateOnBlur={true}
        onSubmit={async (values, { setSubmitting, validateForm }) => {
          setSubmitting(true);
          validateForm();
          setSubmitting(false);
          submitAndNavigate(values);
        }}
      >
        {(formik) => {
          const { isValid, dirty, validateForm, errors, isValidating } = formik;
          return (
            <>
              <form
                onReset={formik.handleReset}
                onSubmit={formik.handleSubmit}
                onKeyDown={(e) => {
                  handleKeyDown(e);
                }}
              >
                <div className={styles.form}>
                  <Container maxWidth="sm" className={styles.container}>
                    <Grid container spacing={1}>
                      <Grid item xs={8} sm={10}>
                        <h4 className={styles.title}>
                          {t('memberInfo.pageHeader')}
                        </h4>
                      </Grid>
                      <Grid item xs={4} sm={2} textAlign="right">
                        <BeaconButton />
                      </Grid>
                    </Grid>
                    {formik.errors && submitAttempted && (
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          {Object.values(formik.errors).map((e, idx) => (
                            <ErrorBox errorMessage={e.toString()} key={idx} />
                          ))}
                        </Grid>
                      </Grid>
                    )}
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <span className={styles.formlabel}>
                          {t('memberInfo.insuranceCompany')}
                        </span>
                        <Autocomplete
                          disablePortal
                          id="provider-autocomplete"
                          options={[affiliate]}
                          getOptionLabel={(ea) => (ea ? ea.display_string : '')}
                          value={formik.values.enrollmentAffiliate}
                          noOptionsText={`${t('memberInfo.noResultsFound')}`}
                          popupIcon={<ExpandMoreIcon fontSize="large" />}
                          disabled={true}
                          readOnly={true}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              inputProps={{
                                ...params.inputProps,
                                'aria-label': t(
                                  'memberInfo.insuranceCompany'
                                ).toString(),
                              }}
                            />
                          )}
                          onChange={(event, newValue) => {
                            formik.setFieldValue(
                              'enrollmentAffiliate',
                              newValue
                            );
                          }}
                          onBlur={() =>
                            formik.setFieldTouched('enrollmentAffiliate', true)
                          }
                        />
                      </Grid>
                    </Grid>
                    <div
                      style={{
                        backgroundColor: '#FFDA7B',
                        padding: isMobile ? 24 : 40,
                      }}
                    >
                      <Grid container spacing={1} style={{ marginBottom: 32 }}>
                        <Grid item xs={12}>
                          <span className={styles.ineligibleMessage}>
                            {i18next.t('ineligible.notPartner', {
                              insuranceCompany: affiliate
                                ? affiliate.display_string
                                : '',
                            })}
                            <br />
                            {t('ineligible.notPartner1')}
                          </span>
                        </Grid>
                      </Grid>
                      <Divider
                        color="#000000"
                        sx={{ my: 2, borderBottomWidth: 1 }}
                      />
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <span className={styles.formlabel}>
                            {t('ineligible.bestEmail')}
                          </span>
                          <TextField
                            className="wellinks-input-bold"
                            name="email"
                            id="email"
                            placeholder={
                              isMobile
                                ? emailPlaceholder.trim()
                                : emailPlaceholder
                            }
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.email}
                            error={
                              formik.errors.email && formik.touched.email
                                ? true
                                : false
                            }
                            fullWidth
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="end">
                                  <Icons.AtSymbol width={20} height={20} />
                                </InputAdornment>
                              ),
                            }}
                            inputProps={{
                              'aria-label': t(
                                'ineligible.bestEmail'
                              ).toString(),
                            }}
                          />

                          {formik.errors.email && formik.touched.email && (
                            <ErrorBox errorMessage={formik.errors.email} />
                          )}
                        </Grid>
                        <Grid item xs={12}>
                          <span className={styles.formlabel}>
                            {t('ineligible.stateLivingIn')}
                          </span>
                          <Autocomplete
                            disablePortal
                            id="state-autocomplete"
                            className="wellinks-input-bold"
                            options={usStatesList}
                            value={formik.values.state}
                            noOptionsText={`${t('memberInfo.noResultsFound')}`}
                            popupIcon={<ExpandMoreIcon fontSize="large" />}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                error={
                                  !!(
                                    formik.errors.state && formik.touched.state
                                  )
                                }
                                inputProps={{
                                  ...params.inputProps,
                                  'aria-label':
                                    t('contactInfo.state').toString(),
                                }}
                              />
                            )}
                            onChange={(event, stateName) => {
                              formik.setFieldValue('state', stateName);
                            }}
                            onBlur={() => formik.setFieldTouched('state', true)}
                          />
                          {formik.errors.state && formik.touched.state && (
                            <ErrorBox
                              errorMessage={formik.errors.state || ''}
                            />
                          )}
                        </Grid>
                      </Grid>
                    </div>
                    <Grid container justifyContent="flex-end">
                      <Button
                        id="ineligible-submit"
                        variant="text"
                        disabled={isSubmitting}
                        className={'wl-button'}
                        type="submit"
                        onClick={() => {
                          setSubmitAttempted(true);
                          if (!formik.isValid || !formik.dirty) {
                            scrollToTop();
                          }
                        }}
                      >
                        {t('ineligible.submit')}
                      </Button>
                    </Grid>
                  </Container>
                </div>
              </form>
            </>
          );
        }}
      </Formik>
      <Dialog
        open={leadSaved}
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '100%',
              maxWidth: '421px', // Set your width here
            },
          },
        }}
      >
        <DialogTitle className="wl-modal-title">
          {t('memberInfo.elibibility_title')}
        </DialogTitle>
        <DialogContent>
          <Box whiteSpace={'pre-line'}>
            <p>{t('memberInfo.elibibility_content')}</p>
            <p>{t('memberInfo.elibibility_content1')}</p>
          </Box>
          <Box textAlign={'center'} marginTop={2}>
            <a href={`mailto:${t('memberInfo.supportEmail')}`}>
              {t('memberInfo.supportEmail')}
            </a>
          </Box>
        </DialogContent>
      </Dialog>
      <Dialog open={errorModalOpen}>
        <DialogTitle>Content Error</DialogTitle>
        <DialogContent>
          Error loading content, please refresh the page and try again.
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default CollectIneligibleInfo;
