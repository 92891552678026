import React, { useEffect, useState } from 'react';

import {
  Alert,
  Button,
  Container,
  Grid,
  InputAdornment,
  TextField,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { UpdateClientMutation } from '@wellinks/wellinks-healthie/types/src/generated/graphql';
import { Formik } from 'formik';
import { loader } from 'react-global-loader';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import styles from './Signup.module.scss';
import SignupHeader from './SignupHeader';
import { hubbleUserSignup } from '../../api/EnrollmentApi';
import { signUpPatient, updateHealthieClientInfo } from '../../api/HealthieAPI';
import {
  createHelpScoutConversation,
  helpScoutProcess,
} from '../../api/HelpScoutApi';
import { Logger } from '../../App';
import backgroundImage from '../../assets/images/onboarding.png';
import { handleKeyDown, scrollToTop } from '../../helpers/pageHelpers';
import useNavBlocker from '../../hooks/useNavBlocker';
import { CreatePatientRequest } from '../../interfaces/Healthie';
import { CreateMemberRequest } from '../../interfaces/Hubble';
import { MemberCredentials } from '../../interfaces/MemberCredentials';
import { MemberInformation } from '../../interfaces/MemberInformation';
import { Store } from '../../store/context';
import { updateCredentialsInformationForm } from '../../store/credentialsInfo/actions';
import {
  updateHealthieStore,
  updateHelpScoutConversationID,
  updateWellinksInfo,
} from '../../store/ext/actions';
import BeaconButton from '../UIComponents/BeaconButton/BeaconButton';
import ErrorBox from '../UIComponents/ErrorBox';
import * as Icons from '../UIComponents/VectorIcons';

function CollectCredentials() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const pageTitle = t('collectCreds.pageTitle');
  const pageDescription = t('collectCreds.pageDescription');
  const passwordReqs = Object.values(
    t('collectCreds.passwordRequirements', { returnObjects: true })
  );

  const urlParams = new URLSearchParams(location.search);

  const {
    credentialsInfoState,
    credentialsInfoDispatch,
    providerInfoState,
    contactInfoState,
    extState,
    extDispatch,
  } = React.useContext(Store);

  const [showPassword, setshowPassword] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [submitAttempted, setSubmitAttempted] = useState(false);
  const [credentialsInfo, setCredentialsInfo] = useState<MemberCredentials>(
    credentialsInfoState.credentialsInformation
  );
  const [signupEmailAPIError, setsignupEmailAPIError] = useState('');
  const [signupPasswordAPIError, setsignupPasswordAPIError] = useState('');
  const [signupGenericPIError, setsignupGenericAPIError] = useState(false);

  useNavBlocker('Changes you made may not be saved.');

  useEffect(() => {
    Logger.info('Credentials page loaded.');
    let isMounted = true; //cancel async calls if unmounted early

    const createInitialHelpScoutConversation = async () => {
      const helpScoutConversationResponse = await createHelpScoutConversation(
        helpScoutProcess.termsOfServiceCompleted.buildHelpScoutConversation(
          providerInfoState.memberInformation,
          contactInfoState
        )
      );

      if (isMounted && helpScoutConversationResponse.conversationID) {
        extDispatch(
          updateHelpScoutConversationID(
            helpScoutConversationResponse.conversationID
          )
        );
      }
    };

    //verify that the conversationID does not exist (if navigating back forth in the form)
    if (!extState.HelpScout.conversationID && contactInfoState.submitted) {
      createInitialHelpScoutConversation();
    }

    return () => {
      isMounted = false;
    };
  }, [signupEmailAPIError, signupPasswordAPIError, signupGenericPIError]); //run once on mount

  const healthieSignUp = async (values: MemberCredentials) => {
    const memberInfo: MemberInformation = providerInfoState.memberInformation;

    const healthieSignUpData: CreatePatientRequest =
      memberInfo.primaryPolicyHolder
        ? {
          email: values.email,
          firstName:
              memberInfo.insuredPreferredName || memberInfo.insuredFirstName,
          lastName: memberInfo.insuredLastName,
          legalName: memberInfo.insuredFirstName,
          password: values.password,
          phoneNumber: contactInfoState.phoneNumber,
        }
        : {
          email: values.email,
          firstName: memberInfo.preferredName || memberInfo.firstName,
          lastName: memberInfo.lastName,
          legalName: memberInfo.firstName,
          password: values.password,
          phoneNumber: contactInfoState.phoneNumber,
        };

    return await signUpPatient(healthieSignUpData);
  };

  const hubbleSignUp = async (
    values: MemberCredentials,
    healthieID: string
  ) => {
    const memberInfo = providerInfoState.memberInformation;
    const contactInfo = contactInfoState;
    const hubbleSignUpUserData: CreateMemberRequest = {
      healthie_id: healthieID,
      email: values.email,
      first_name: memberInfo.primaryPolicyHolder
        ? memberInfo.insuredFirstName
        : memberInfo.firstName,
      last_name: memberInfo.primaryPolicyHolder
        ? memberInfo.insuredLastName
        : memberInfo.lastName,
      phone_number: contactInfo.phoneNumber,
      preferred_name: memberInfo.primaryPolicyHolder
        ? memberInfo.insuredPreferredName
        : memberInfo.preferredName,
      date_of_birth: memberInfo.primaryPolicyHolder
        ? memberInfo.insuredDateOfBirth
        : memberInfo.dateOfBirth,
      zip_code: contactInfo.zip,
      gender_description: memberInfo.genderName,
      self_described_gender: memberInfo.selfDescribedGender,
      state_name: contactInfo.state,
      enrollment_affiliate_id: memberInfo.enrollmentAffiliate.id,
      contract_code: memberInfo.contractCode,
      referral: {
        mail_referral: memberInfo.referralSource.mailReferral,
        email_referral: memberInfo.referralSource.emailReferral,
        phone_referral: memberInfo.referralSource.phoneReferral,
        provider_referral: memberInfo.referralSource.providerReferral,
        other_referral: memberInfo.referralSource.otherReferral,
        provider_name: memberInfo.providerReferralSource || null,
        other_referral_info: memberInfo.otherReferralSource || null,
        url_referral_medium: urlParams.get('medium'),
        url_referral_source: urlParams.get('source'),
        url_referral_campaign: urlParams.get('campaign'),
        url_referral_content: urlParams.get('content'),
      },
    };

    return await hubbleUserSignup(hubbleSignUpUserData);
  };

  // parses data we have available and builds our arguments to healthie
  const updateHealthieClient = async (
    healthieID: string,
    wellinksMemberId: string | null
  ): Promise<UpdateClientMutation> => {
    // vars to pull args from
    const memberInfo = providerInfoState.memberInformation;
    const contactInfo = contactInfoState;

    // determine and format birthday
    const dateOfBirth: Date = memberInfo.primaryPolicyHolder
      ? memberInfo.insuredDateOfBirth
      : memberInfo.dateOfBirth;
    const dobString = dateOfBirth.toISOString().slice(0, 10);

    // determine gender mapping
    let gender;
    let genderIdentity = null;
    if (
      memberInfo.genderName === 'Male' ||
      memberInfo.genderName === 'Female'
    ) {
      gender = memberInfo.genderName;
    } else if (memberInfo.genderName === 'Prefer not to say') {
      gender = null;
    } else {
      gender = 'Other';
      genderIdentity = memberInfo.selfDescribedGender;
    }

    // make the call
    return updateHealthieClientInfo(
      healthieID,
      wellinksMemberId,
      dobString,
      gender,
      genderIdentity,
      contactInfo.addressLine1,
      contactInfo.addressLine2 || null,
      contactInfo.city,
      contactInfo.state_abbr,
      contactInfo.zip,
      'US'
    );
  };

  const signupAndNavigate = async (values: MemberCredentials) => {
    let healthieSignupResponse = null;
    let hubbleSignupResponse = null;
    let healthieID = null;
    loader.show();
    try {
      healthieSignupResponse = await healthieSignUp(values);

      setsignupGenericAPIError(false);

      //Logging this, as Healthie doesn't throw errors, but returns a 200 with errors in the messages body
      Logger.debug('Healthie user signup response: ', healthieSignUp);
    } catch (error) {
      loader.hide();
      setsignupEmailAPIError('');
      setsignupPasswordAPIError('');
      setsignupGenericAPIError(true);
      Logger.error(error as Error, 'Error creating Healthie user', {
        email: values.email,
        path: 'Credentials page.',
      });
      return;
    }

    const messages =
      healthieSignupResponse && healthieSignupResponse.signUp?.messages;

    //if healthie throws errors
    if (messages) {
      loader.hide();
      const invalidEmailMsg = 'is invalid';
      const emailExistsMsg = 'This email already exists on a';
      let finalEmailMsg = '';
      const emailMsg = messages.find((msg) => msg?.field === 'email')?.message;
      if (emailMsg?.includes(invalidEmailMsg)) {
        finalEmailMsg = `${t('collectCreds.invalidEmailError')}`;
      } else if (emailMsg?.includes(emailExistsMsg)) {
        const loginLink = `<a href="http://my.wellinks.com">${t(
          'memberInfo.patientExistsIneligibleMessage1'
        )}</a>`;
        const emailLink = `<a href="mailto:${t('memberInfo.supportEmail')}">${t(
          'memberInfo.supportEmail'
        )}</a>`;
        finalEmailMsg =
          t('collectCreds.emailExistsMessage') +
          loginLink +
          t('collectCreds.emailExistsMessage1') +
          emailLink +
          '.';
      } else {
        finalEmailMsg = t('collectCreds.unknownError');
      }

      setsignupEmailAPIError(finalEmailMsg);
      setsignupPasswordAPIError(
        messages.find((msg) => msg?.field === 'password')?.message || ''
      );
      return;
    }
    //if healthie user creation is successful
    else {
      setsignupEmailAPIError('');
      setsignupPasswordAPIError('');

      healthieID =
        healthieSignupResponse &&
        healthieSignupResponse.signUp?.user &&
        healthieSignupResponse.signUp?.user.id;

      // If healthie user creation was successful, create user in Hubble
      if (healthieID) {
        if (
          healthieSignupResponse.signUp?.user?.id &&
          healthieSignupResponse.signUp?.token
        ) {
          extDispatch(
            updateHealthieStore(
              healthieSignupResponse.signUp.user.id,
              healthieSignupResponse.signUp.token
            )
          );

          sessionStorage.setItem('hwt', healthieSignupResponse.signUp?.token);
        }

        Logger.info(
          'Successful Healthie account creation and fetch of HealthieID. ',
          {
            email: values.email,
            path: 'Credentials page.',
          }
        );

        try {
          hubbleSignupResponse = await hubbleSignUp(values, healthieID || '');
          if (hubbleSignupResponse) {
            const member_id = hubbleSignupResponse.member_id;
            const wellinks_member_id = hubbleSignupResponse.wellinks_member_id;
            const contracts_patients_id =
              hubbleSignupResponse.contracts_patients_id;
            const eligibles_id = hubbleSignupResponse.eligibles_id;
            const contract_name = hubbleSignupResponse.contract_name;

            extDispatch(
              updateWellinksInfo(
                member_id,
                wellinks_member_id,
                contracts_patients_id,
                contract_name
              )
            );

            Logger.info('Successful Hubble account creation', {
              email: values.email,
              healthie_id: healthieID,
              memberID: member_id,
              path: 'Credentials page.',
            });
          }
          setsignupGenericAPIError(false);
        } catch (error) {
          loader.hide();
          setsignupGenericAPIError(true);
          Logger.error(error as Error, 'Error creating Hubble user', {
            email: values.email,
            healthie_id: healthieID,
            path: 'Credentials page.',
          });
          return;
        }
      }
    }

    // if healthie call is successful update the profile there with extra data
    if (healthieID) {
      try {
        await updateHealthieClient(
          healthieID,
          hubbleSignupResponse?.wellinks_member_id || null
        );

        Logger.info('Successfully updated client on healthie. ', {
          email: values.email,
          healthie_id: healthieID,
          member_id: hubbleSignupResponse?.member_id,
          path: 'Credentials page.',
        });
      } catch (error) {
        loader.hide();
        // eat the exception since we don't care if it errors
        Logger.error(error as Error, 'Error updating client on healthie', {
          email: values.email,
          healthie_id: healthieID,
          member_id: hubbleSignupResponse?.member_id,
          path: 'Credentials page.',
        });
      }
    }

    //If signup was successful navigate to next screen
    loader.hide();
    navigate(
      {
        pathname: '/setupFirstAppointment',
        search: location.search,
      },
      { replace: true }
    );
  };

  // Healthie regex -> /\A[\w+\-.]+@[a-z\d\-]+(\.[a-z\d\-]+)*\.[a-z]+\z/i
  // const oldEmailRegex = new RegExp(/^[^@.]+[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-]*$/);
  const emailRegex = new RegExp(
    /^[\w+-.]+@[a-z\d-]+(\.[a-z\d-]+)*\.[a-z]+$/,
    'i'
  );

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .required(`${t('collectCreds.emailRequired')}`)
      .matches(emailRegex, `${t('collectCreds.invalidEmailError')}`),
    password: yup
      .string()
      .min(8, `${t('collectCreds.passwordMin8')}`)
      .matches(/[a-z]/, `${t('collectCreds.passwordLower')}`)
      .matches(/[A-Z]/, `${t('collectCreds.passwordUpper')}`)
      .matches(
        /[\d]|[.,/#!$%^&*;:{}=\-_`~()]+/,
        `${t('collectCreds.passwordSpecialChar')}`
      )
      .required(`${t('collectCreds.passwordRequired')}`),
  });

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  if (signupEmailAPIError || signupPasswordAPIError || signupGenericPIError) {
    scrollToTop();
  }

  return (
    <>
      <SignupHeader
        title={pageTitle}
        description={pageDescription}
        activeStepNumber={2}
      />

      <div
        style={{
          backgroundImage: isMobile ? 'none' : `url(${backgroundImage})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center top',
        }}
      >
        <Formik
          initialValues={credentialsInfo}
          enableReinitialize={true}
          validationSchema={validationSchema}
          validateOnChange={false}
          validateOnBlur={true}
          onSubmit={async (values, { setSubmitting, validateForm }) => {
            setSubmitting(true);
            validateForm();
            setSubmitting(false);
            credentialsInfoDispatch(updateCredentialsInformationForm(values));
            signupAndNavigate(values);
          }}
        >
          {(formik) => {
            return (
              <form
                onReset={formik.handleReset}
                onSubmit={formik.handleSubmit}
                onKeyDown={(e) => {
                  handleKeyDown(e);
                }}
              >
                <div className={styles.form}>
                  <Container maxWidth="sm" className={styles.container}>
                    {signupGenericPIError && (
                      <Alert color="error">
                        <strong>Error:</strong>
                        {t('collectCreds.genericAPIError')}:{' '}
                        <a href="mailto:support@wellinks.com">
                          support@wellinks.com
                        </a>
                      </Alert>
                    )}
                    <Grid container spacing={1}>
                      <Grid item xs={8} sm={10}>
                        <h4 className={styles.title}>
                          {t('collectCreds.pageHeader')}
                        </h4>
                      </Grid>
                      <Grid item xs={4} sm={2} textAlign="right">
                        <BeaconButton />
                      </Grid>
                    </Grid>
                    {formik.errors && submitAttempted && (
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          {Object.values(formik.errors).map((e, idx) => (
                            <ErrorBox errorMessage={e} key={idx} />
                          ))}
                        </Grid>
                      </Grid>
                    )}
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <span className={styles.formlabel}>
                          {t('collectCreds.emailBold')}{' '}
                          <span>{t('collectCreds.emailRegular')}</span>
                        </span>
                        <TextField
                          name="email"
                          id="email"
                          placeholder={`${t('collectCreds.emailPlaceholder')}`}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.email}
                          error={
                            formik.errors.email && formik.touched.email
                              ? true
                              : false
                          }
                          fullWidth
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="end">
                                <Icons.AtSymbol width={20} height={20} />
                              </InputAdornment>
                            ),
                          }}
                          inputProps={{
                            'aria-label': t(
                              'collectCreds.emailBold'
                            ).toString(),
                          }}
                        />
                        <ErrorBox
                          errorMessage={
                            formik.errors.email && formik.touched.email
                              ? formik.errors.email
                              : signupEmailAPIError
                                ? signupEmailAPIError
                                : t('collectCreds.emailInfo')
                          }
                          hasErrored={
                            (formik.errors.email && formik.touched.email) ||
                            signupEmailAPIError
                              ? true
                              : false
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <span className={styles.formlabel}>
                          {t('collectCreds.createPassword')}
                        </span>
                        <TextField
                          name="password"
                          id="password"
                          placeholder={`${t(
                            'collectCreds.passwordPlaceholder'
                          )}`}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          type={showPassword ? 'text' : 'password'}
                          value={formik.values.password}
                          error={
                            formik.errors.password && formik.touched.password
                              ? true
                              : false
                          }
                          fullWidth
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="end">
                                <Icons.Lock width={14} height={18} />
                              </InputAdornment>
                            ),
                            endAdornment: (
                              <InputAdornment position="end">
                                <a
                                  onClick={() => {
                                    setshowPassword(!showPassword);
                                  }}
                                >
                                  {showPassword ? (
                                    <Icons.HidePassword />
                                  ) : (
                                    <Icons.ViewPassword />
                                  )}
                                </a>
                              </InputAdornment>
                            ),
                          }}
                          inputProps={{
                            'aria-label': t(
                              'collectCreds.createPassword'
                            ).toString(),
                          }}
                        />
                        {(formik.errors.password && formik.touched.password) ||
                        signupPasswordAPIError ? (
                            <ErrorBox
                              errorMessage={
                                signupPasswordAPIError
                                  ? signupPasswordAPIError
                                  : formik.errors.password || ''
                              }
                            />
                          ) : null}
                      </Grid>
                      <Grid item xs={12}>
                        <span className={styles.formlabel}>
                          {t('collectCreds.passwordRequirementsTitle')}
                        </span>
                        <ul>
                          {passwordReqs.map((req) => (
                            <li key={req} className={styles.titleContent}>
                              {req}
                            </li>
                          ))}
                        </ul>
                      </Grid>
                    </Grid>
                    {isSubmitting && <div>Loading...</div>}
                    <Grid container justifyContent="center">
                      <Button
                        id="create-login-button"
                        variant="text"
                        disabled={isSubmitting}
                        className={'wl-button'}
                        type="submit"
                        onClick={() => {
                          setSubmitAttempted(true);
                          if (!formik.isValid || !formik.dirty) {
                            scrollToTop();
                          }
                        }}
                      >
                        {t('collectCreds.createLogin')}
                      </Button>
                    </Grid>
                  </Container>
                </div>
              </form>
            );
          }}
        </Formik>
      </div>
    </>
  );
}

export default CollectCredentials;
