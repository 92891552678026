import React from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import styles from './AgreementDialog.module.scss';

interface AgreementDialogProps {
  display_dialog: boolean;
  close_dialog: () => any;
}

export default function AgreementDialog({
  display_dialog,
  close_dialog,
}: AgreementDialogProps) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={display_dialog}
        onClose={close_dialog}
        aria-labelledby="agreement-dialog-title"
      >
        <DialogTitle
          id="agreement-dialog-title"
          className={styles.agreementTitle}
        >
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item className={styles.agreementTitle} xs={10}>
              What are these agreements?
            </Grid>
            <Grid item xs={2}>
              <DialogActions>
                <CloseIcon
                  onClick={close_dialog}
                  id="agreements-modal-close-button"
                />
              </DialogActions>
            </Grid>
          </Grid>
        </DialogTitle>

        <DialogContent>
          {/* <DialogContentText> */}
          <Box sx={{ mt: 2 }} className={styles.agreementSubtitle}>
            Terms of Service
          </Box>

          <Box>
            We are committed to our responsibility for the services we provide.
            It&apos;s important that you understand and agree to the rules for
            using these services. In general:
          </Box>

          <Box sx={{ my: 2 }}>
            <List sx={{ listStyleType: 'disc', pl: 2 }}>
              <ListItem sx={{ display: 'list-item' }}>
                Our website content, devices, coaching and other app features
                are meant to support your unique health journey. You should
                consult your health care provider before modifying your current
                treatment plan.
              </ListItem>
              <ListItem sx={{ display: 'list-item' }}>
                Use of our services is based on mutual respect. Wellinks staff
                will treat you, your privacy and your rights with dignity in all
                aspects of service. Likewise, you agree to treat our staff,
                technology and others accessing our services with the same
                authenticity and regard.
              </ListItem>
              <ListItem sx={{ display: 'list-item' }}>
                Wellinks may terminate your access to services if they are used
                with malicious, criminal or commercial intent. This document
                covers your legal rights with regards to any disputes.
              </ListItem>
            </List>
          </Box>

          <Box sx={{ my: 2 }}>
            The Terms of Service further details the rules, rights and
            responsibilities of using our software and services.
          </Box>

          <Box sx={{ my: 2 }}>
            <Box className={styles.agreementSubtitle}>Privacy Policy</Box>
            To improve our services, we collect data about how you use them.
            This policy outlines what we do with that data, and how we work to
            protect your privacy.
          </Box>

          <Box sx={{ my: 2 }}>
            <Box className={styles.agreementSubtitle}>
              Informed Consent for Telehealth Services
            </Box>
            We strive to offer a safe and secure space to provide you care.
            These are the limitations and risks of using telehealth services.
          </Box>

          <Box sx={{ my: 2 }}>
            <Box className={styles.agreementSubtitle}>
              Notice of Privacy Practices
            </Box>
            You have a right to the privacy of your health information.
            Here&apos;s how we may use the information you share, and what we do
            to keep it secure.
          </Box>
          {/* </DialogContentText> */}
        </DialogContent>
      </Dialog>
    </div>
  );
}
