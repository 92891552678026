import React from 'react';

import LinearStepper from '../UIComponents/LinearStepper';

function SignupHeader({
  title,
  description,
  activeStepNumber,
}: {
  title: string;
  description: string;
  activeStepNumber: number;
}) {
  return (
    <div className="section-hero-about wf-section">
      <div className="container">
        <div className="about-hero-wrap">
          <div className="subhead-small blue-color-text">ENROLLMENT</div>
          <h1 className="h1-hero black-color-text">{title}</h1>
          <p className="subtext-home large black-color-text text-opacity-80">
            {description}
          </p>
        </div>
        <div style={{ marginBottom: '50px' }}>
          <LinearStepper activeStep={activeStepNumber}></LinearStepper>
        </div>
      </div>
    </div>
  );
}

export default SignupHeader;
