import { Maybe } from '@wellinks/wellinks-healthie/types/src/generated/graphql';
import dayjs, { Dayjs } from 'dayjs';
import * as moment from 'moment-timezone';

const abbrs: { [key: string]: string } = {
  EST: 'ET',
  EDT: 'ET',
  CST: 'CT',
  CDT: 'CT',
  MST: 'MT',
  MDT: 'MT',
  PST: 'PT',
  PDT: 'PT',
};

const wellinksTimeZoneAbbreviation = (abbr: string) => {
  return abbrs[abbr] || abbr;
};

export const getUserIANATimeZone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

export const getUserFormattedTimeZone = () => {
  return wellinksTimeZoneAbbreviation(
    moment.tz(getUserIANATimeZone()).zoneAbbr()
  );
};

export const getHealthieDayJSDate = (
  dateTime: Maybe<string> | undefined
): Dayjs => {
  return dayjs(dateTime, 'YYYY-MM-DD hh:mm:ss ZZ');
};
