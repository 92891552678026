import React, { useEffect, useState } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Link,
  Stack,
  TextField,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Formik } from 'formik';
import { loader } from 'react-global-loader';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import styles from './Signup.module.scss';
import SignupHeader from './SignupHeader';
import { getStates } from '../../api/EnrollmentApi';
import { Logger } from '../../App';
import { handleKeyDown, scrollToTop } from '../../helpers/pageHelpers';
import useNavBlocker from '../../hooks/useNavBlocker';
import { ContactInfo } from '../../interfaces/ContactInfo';
import { USState } from '../../interfaces/USStaate';
import { updateContactInformationForm } from '../../store/contactInfo/actions';
import { Store } from '../../store/context';
import AgreementDialog from '../UIComponents/AgreementDialog/AgreementDialog';
import BeaconButton from '../UIComponents/BeaconButton/BeaconButton';
import ErrorBox from '../UIComponents/ErrorBox';

function CollectContactInfo() {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const pageTitle = t('contactInfo.pageTitle');
  const pageDescription = t('contactInfo.pageDescription');
  const { contactInfoState, contactInfoDispatch } = React.useContext(Store);
  const [contactInfo, setContactInfo] = useState<ContactInfo>(contactInfoState);
  const [isSubmitting, setSubmitting] = useState(false);
  const [submitAttempted, setSubmitAttempted] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [usStatesList, setUsStatesList] = useState<USState[]>([]);
  const [selectedState, setSelectedState] = useState<USState | null>(null);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const dialogProps = {
    display_dialog: open,
    close_dialog: handleClose,
  };

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  // eslint-disable-next-line

  const zipRegex = /(^\d{5}$)|(^\d{5}-\d{4}$)/;

  const validationSchema = yup.object().shape({
    phoneNumber: yup
      .string()
      .matches(phoneRegExp, `${t('contactInfo.phoneNotValid')}`)
      .min(10, `${t('contactInfo.phoneMin10')}`)
      .required(`${t('contactInfo.phoneRequired')}`),
    preferPhoneCall: yup.boolean(),
    addressLine1: yup.string().required(`${t('contactInfo.addressRequired')}`),
    addressLine2: yup.string(),
    city: yup.string().required(`${t('contactInfo.cityRequired')}`),
    state: yup.string().required(`${t('contactInfo.stateRequired')}`),
    zip: yup
      .string()
      .matches(zipRegex, `${t('contactInfo.zipInvalid')}`)
      .required(`${t('contactInfo.zipRequired')}`),
    privacyPolicyAccepted: yup
      .bool()
      .oneOf([true], `${t('contactInfo.agreeTo7')}`)
      .required(`${t('contactInfo.acceptPrivacyPolicy')}`),
    consentForCareAccepted: yup
      .bool()
      .oneOf([true], `${t('contactInfo.agreeTo8')}`)
      .required(`${t('contactInfo.acceptConsentCare')}`),
  });

  useNavBlocker('Changes you made may not be saved.');

  useEffect(() => {
    loader.show();
    async function fetchStates() {
      try {
        const states = await getStates();
        setUsStatesList(states);
        loader.hide();
      } catch (error) {
        loader.hide();
        setErrorModalOpen(true);
        Logger.error(
          error as Error,
          'Failed to get a list of states.',
          'Collect Contact Info page.'
        );
      }
    }
    fetchStates();
  }, [errorModalOpen]);

  if (errorModalOpen) {
    scrollToTop();
  }

  return (
    <div>
      <SignupHeader
        title={pageTitle}
        description={pageDescription}
        activeStepNumber={1}
      />
      <Formik
        initialValues={contactInfo}
        enableReinitialize={true}
        validationSchema={validationSchema}
        validateOnChange={true}
        validateOnBlur={true}
        onSubmit={async (values, { setSubmitting, validateForm }) => {
          setSubmitting(true);
          validateForm();
          setSubmitting(false);
          contactInfoDispatch(
            updateContactInformationForm({
              ...values,
              state_abbr: usStatesList.find(
                (usState) => usState.name == values.state
              )?.abbreviation,
            })
          );
          navigate(
            { pathname: '/credentials', search: location.search },
            { replace: true }
          );
        }}
      >
        {(formik) => {
          const { isValid, dirty, validateForm, errors, isValidating } = formik;
          return (
            <>
              <form
                onReset={formik.handleReset}
                onSubmit={formik.handleSubmit}
                onKeyDown={(e) => {
                  handleKeyDown(e);
                }}
              >
                <div className={styles.form}>
                  <Container maxWidth="sm" className={styles.container}>
                    <Grid container spacing={1}>
                      <Grid item xs={8} sm={10}>
                        <h4 className={styles.title}>
                          {t('contactInfo.pageHeader')}
                        </h4>
                      </Grid>
                      <Grid item xs={4} sm={2} textAlign="right">
                        <BeaconButton />
                      </Grid>
                    </Grid>
                    {formik.errors && submitAttempted && (
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          {Object.values(formik.errors).map((e, idx) => (
                            <ErrorBox errorMessage={e} key={idx} />
                          ))}
                        </Grid>
                      </Grid>
                    )}
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <span className={styles.formlabel}>
                          {t('contactInfo.phone')}{' '}
                        </span>
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          id="phone-number-textfield"
                          name="phoneNumber"
                          type="text"
                          placeholder="xxx-xxx-xxxx"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.phoneNumber}
                          error={
                            formik.errors.phoneNumber &&
                            formik.touched.phoneNumber
                              ? true
                              : false
                          }
                          inputProps={{
                            'aria-label': t('contactInfo.phone').toString(),
                          }}
                        />
                        {formik.errors.phoneNumber &&
                          formik.touched.phoneNumber && (
                          <ErrorBox
                            errorMessage={formik.errors.phoneNumber || ''}
                          />
                        )}
                      </Grid>

                      <Grid item xs={12}>
                        <span className={styles.titleContent}>
                          {t('contactInfo.videoCallInfo')}
                        </span>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControlLabel
                          name="preferPhoneCall"
                          control={
                            <Checkbox
                              id="prefer-phone-call-checkbox"
                              checked={formik.values.preferPhoneCall}
                            />
                          }
                          onChange={() => {
                            formik.setFieldValue(
                              'preferPhoneCall',
                              !formik.values.preferPhoneCall
                            );
                          }}
                          label={`${t('contactInfo.preferPhoneCall')}`}
                        />
                      </Grid>
                    </Grid>

                    <h3>{t('contactInfo.shippingAddress')}</h3>

                    <Grid item xs={12}>
                      <Box sx={{ my: 1 }} className={styles.body}>
                        {t('contactInfo.welcomeKit')}
                      </Box>
                    </Grid>

                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <span className={styles.formlabel}>
                          {t('contactInfo.address1')}{' '}
                        </span>
                        <TextField
                          id="address-line-1-textfield"
                          name="addressLine1"
                          placeholder=""
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.addressLine1}
                          error={
                            formik.errors.addressLine1 &&
                            formik.touched.addressLine1
                              ? true
                              : false
                          }
                          fullWidth
                          inputProps={{
                            'aria-label': t('contactInfo.address1').toString(),
                          }}
                        />
                        {formik.errors.addressLine1 &&
                          formik.touched.addressLine1 && (
                          <ErrorBox
                            errorMessage={formik.errors.addressLine1 || ''}
                          />
                        )}
                      </Grid>

                      <Grid item xs={12}>
                        <span className={styles.formlabel}>
                          {t('contactInfo.address2')}{' '}
                        </span>
                        <TextField
                          id="address-line-2-textfield"
                          name="addressLine2"
                          placeholder=""
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.addressLine2}
                          fullWidth
                          inputProps={{
                            'aria-label': t('contactInfo.address2').toString(),
                          }}
                        />
                        {formik.errors.addressLine2 &&
                          formik.touched.addressLine2 && (
                          <div className={styles.errorText}>
                            {formik.errors.addressLine2}
                          </div>
                        )}
                      </Grid>
                    </Grid>

                    <Grid container direction="row" spacing={2}>
                      <Grid item xs={12} sm={7}>
                        <span className={styles.formlabel}>
                          {t('contactInfo.city')}{' '}
                        </span>
                        <TextField
                          id="city-textfield"
                          name="city"
                          placeholder=""
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.city}
                          error={
                            formik.errors.city && formik.touched.city
                              ? true
                              : false
                          }
                          fullWidth
                          inputProps={{
                            'aria-label': t('contactInfo.city').toString(),
                          }}
                        />
                        {formik.errors.city && formik.touched.city && (
                          <ErrorBox errorMessage={formik.errors.city || ''} />
                        )}
                      </Grid>

                      <Grid item xs={12} sm={5}>
                        <span className={styles.formlabel}>
                          {t('contactInfo.state')}{' '}
                        </span>
                        <Autocomplete
                          disablePortal
                          id="state-autocomplete"
                          options={usStatesList}
                          getOptionLabel={(state) => state.name}
                          value={selectedState}
                          noOptionsText={`${t('memberInfo.noResultsFound')}`}
                          popupIcon={<ExpandMoreIcon fontSize="large" />}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              error={
                                !!(formik.errors.state && formik.touched.state)
                              }
                              inputProps={{
                                ...params.inputProps,
                                'aria-label': t('contactInfo.state').toString(),
                              }}
                            />
                          )}
                          onChange={(event, state) => {
                            setSelectedState(state);
                            formik.setFieldValue('state', state?.name);
                          }}
                          onBlur={() => formik.setFieldTouched('state', true)}
                        />
                        {formik.errors.state && formik.touched.state && (
                          <ErrorBox errorMessage={formik.errors.state || ''} />
                        )}
                      </Grid>

                      <Grid item xs={12} sm={8}>
                        <span className={styles.formlabel}>
                          {t('contactInfo.zip')}{' '}
                        </span>
                        <TextField
                          id="zip-textfield"
                          name="zip"
                          placeholder=""
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.zip}
                          error={
                            formik.errors.zip && formik.touched.zip
                              ? true
                              : false
                          }
                          fullWidth
                          inputProps={{
                            'aria-label': t('contactInfo.zip').toString(),
                          }}
                        />
                        {formik.errors.zip && formik.touched.zip && (
                          <ErrorBox errorMessage={formik.errors.zip || ''} />
                        )}
                      </Grid>
                    </Grid>

                    <Divider sx={{ my: 2 }} />

                    <h4 className={styles.title}>
                      {t('contactInfo.agreements')}
                    </h4>

                    <Grid container direction="column" spacing={{ xs: 1 }}>
                      <Grid item xs={12}>
                        <Box sx={{ my: 1 }} className={styles.body}>
                          {t('contactInfo.reviewInfo')}
                        </Box>
                      </Grid>

                      <Grid item xs={12}>
                        <FormControl>
                          <FormControlLabel
                            className={styles.checkboxGroup}
                            style={{ display: 'table', width: '100%' }}
                            control={
                              <div style={{ display: 'table-cell' }}>
                                <Checkbox
                                  id="privacy-policy-checkbox"
                                  className={styles.checkboxGroup}
                                  name="privacyPolicyAccepted" //event.target.name matches the state key in handleReferral source
                                  checked={formik.values.privacyPolicyAccepted}
                                  onChange={() => {
                                    formik.setFieldValue(
                                      'privacyPolicyAccepted',
                                      !formik.values.privacyPolicyAccepted
                                    );
                                  }}
                                />
                              </div>
                            }
                            label={
                              <>
                                {t('contactInfo.agreeTo1')}
                                <a
                                  id="terms-of-service-link"
                                  target="_blank"
                                  rel="noreferrer"
                                  className={styles.tosLinks}
                                  href="https://www.wellinks.com/terms-of-service"
                                >
                                  {t('contactInfo.agreeTo2')}
                                </a>{' '}
                                {t('contactInfo.agreeTo3')}
                                <a
                                  id="privacy-policy-link"
                                  target="_blank"
                                  rel="noreferrer"
                                  className={styles.tosLinks}
                                  href="https://www.wellinks.com/privacy-policy"
                                >
                                  {t('contactInfo.agreeTo4')}
                                </a>
                                .
                              </>
                            }
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={12}>
                        <FormControl>
                          <FormControlLabel
                            className={styles.checkboxGroup}
                            style={{ display: 'table', width: '100%' }}
                            control={
                              <div style={{ display: 'table-cell' }}>
                                <Checkbox
                                  id="consent-for-care-checkbox"
                                  className={styles.checkboxGroup}
                                  name="consentForCareAccepted"
                                  checked={formik.values.consentForCareAccepted}
                                  onChange={() => {
                                    formik.setFieldValue(
                                      'consentForCareAccepted',
                                      !formik.values.consentForCareAccepted
                                    );
                                  }}
                                />
                              </div>
                            }
                            label={
                              <>
                                {t('contactInfo.agreeTo1')}
                                <a
                                  id="consent-for-care-link"
                                  target="_blank"
                                  rel="noreferrer"
                                  className={styles.tosLinks}
                                  href="https://www.wellinks.com/consent-for-care"
                                >
                                  {t('contactInfo.agreeTo5')}
                                </a>{' '}
                                {t('contactInfo.agreeTo3')}
                                <a
                                  id="private-practices-link"
                                  target="_blank"
                                  rel="noreferrer"
                                  className={styles.tosLinks}
                                  href="https://www.wellinks.com/hipaa-privacy"
                                >
                                  {t('contactInfo.agreeTo6')}
                                </a>
                                .
                              </>
                            }
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={12}>
                        <Stack direction="row" justifyContent="end">
                          <Link
                            id="agreements-link"
                            className={styles.agreementLink}
                            onClick={handleOpen}
                          >
                            {t('contactInfo.agreementsWhat')}
                          </Link>
                        </Stack>
                      </Grid>

                      <Grid item xs={12}>
                        <Grid container direction="column" columns={{ xs: 12 }}>
                          <Grid item xs={12} sm={2}>
                            {formik.errors.consentForCareAccepted &&
                              formik.touched.consentForCareAccepted && (
                              <ErrorBox
                                errorMessage={t('contactInfo.agreeTo7')}
                              />
                            )}
                          </Grid>
                          <Grid item xs={12} sm={2}>
                            {formik.errors.privacyPolicyAccepted &&
                              formik.touched.privacyPolicyAccepted && (
                              <div className={styles.errorText}>
                                <ErrorBox
                                  errorMessage={t('contactInfo.agreeTo8')}
                                />
                              </div>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item xs={12}>
                        <Grid
                          container
                          direction="row"
                          justifyContent="space-between"
                          textAlign="right"
                        >
                          <Grid item xs={12}>
                            <Button
                              id="continue-button"
                              variant="text"
                              disabled={isSubmitting}
                              className={'wl-button'}
                              type="submit"
                              onClick={() => {
                                setSubmitAttempted(true);
                                if (!formik.isValid || !formik.dirty) {
                                  scrollToTop();
                                }
                              }}
                            >
                              {t('memberInfo.continue')}
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Container>
                </div>
              </form>
              <AgreementDialog
                display_dialog={open}
                close_dialog={handleClose}
              />
            </>
          );
        }}
      </Formik>
      <Dialog open={errorModalOpen}>
        <DialogTitle>Content Error</DialogTitle>
        <DialogContent>
          Error loading content, please refresh the page and try again.
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default CollectContactInfo;
