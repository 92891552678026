import React from 'react';

import contactInfo, { contactInfoInitialState } from './contactInfo/reducer';
import credentialsInfo, {
  credentialsInfoInitialState,
} from './credentialsInfo/reducer';
import extReducer, { extInitialState } from './ext/reducer';
import providerInfo, { providerInfoInitialState } from './providerInfo/reducer';

export const Store = React.createContext<any>({});

export function StoreProvider(props: any): JSX.Element {
  const [providerInfoState, providerInfoDispatch] = React.useReducer(
    providerInfo,
    providerInfoInitialState
  );
  const [contactInfoState, contactInfoDispatch] = React.useReducer(
    contactInfo,
    contactInfoInitialState
  );
  const [credentialsInfoState, credentialsInfoDispatch] = React.useReducer(
    credentialsInfo,
    credentialsInfoInitialState
  );
  const [extState, extDispatch] = React.useReducer(extReducer, extInitialState);

  return (
    <Store.Provider
      value={{
        providerInfoState,
        providerInfoDispatch,
        contactInfoState,
        contactInfoDispatch,
        credentialsInfoState,
        credentialsInfoDispatch,
        extState,
        extDispatch,
      }}
    >
      {props.children}
    </Store.Provider>
  );
}
