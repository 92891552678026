import {
  ADD_HEALTHIE_SCHEDULING,
  UPDATE_HEALTHIE_STORE,
  UPDATE_HELP_SCOUT_CONVERSATION,
  UPDATE_HELP_SCOUT_CUSTOMER,
  UPDATE_WELLINKS_INFO,
} from './actions';
import { Action } from '../../interfaces/Action';
import { ExtStore } from '../../interfaces/Ext';

export const extInitialState: ExtStore = {
  HelpScout: {
    conversationID: null,
    customerID: null,
  },
  Healthie: {
    userID: null,
    token: null,
  },
  Wellinks: {
    accountCreationAttempted: false,
    wellinksID: null,
    contractsPatientsID: null,
    contractName: null,
    memberId: null,
  },
};

//Reducer for keeping track of external service data
const extReducer = (state: ExtStore, action: Action): ExtStore => {
  switch (action.type) {
  case UPDATE_HELP_SCOUT_CONVERSATION: {
    return {
      ...state,
      HelpScout: { ...state.HelpScout, conversationID: action.payload },
    };
  }
  case UPDATE_HELP_SCOUT_CUSTOMER: {
    return {
      ...state,
      HelpScout: { ...state.HelpScout, customerID: action.payload },
    };
  }
  case UPDATE_WELLINKS_INFO: {
    return {
      ...state,
      Wellinks: {
        ...state.Wellinks,
        accountCreationAttempted: true,
        wellinksID: action.payload.wellinks_member_id,
        memberId: action.payload.member_id,
        contractsPatientsID: action.payload.contracts_patients_id,
        contractName: action.payload.contract_name,
      },
    };
  }
  case UPDATE_HEALTHIE_STORE: {
    return {
      ...state,
      Healthie: {
        ...state.Healthie,
        userID: action.payload.userID,
        token: action.payload.token,
      },
    };
  }
  case ADD_HEALTHIE_SCHEDULING: {
    return {
      ...state,
      Healthie: {
        ...state.Healthie,
        scheduling: action.payload.scheduling,
      },
    };
  }
  default: {
    return state;
  }
  }
};

export default extReducer;
