import React from 'react';

import { Outlet } from 'react-router-dom';

import Footer from '../components/Footer/Footer';
import Header from '../components/Header/Header';
import TopScroll from '../hooks/TopScroll';

function RootLayout() {
  return (
    <>
      <TopScroll />
      <div className="App">
        <Header />
        <main className="rootContainer">
          {/* {navigation.state === 'loading' && <p>Loading...</p>}  */}
          <Outlet />
        </main>
        <Footer />
      </div>
    </>
  );
}

export default RootLayout;
