import { CompleteCheckoutMutation } from '@wellinks/wellinks-healthie/types/src/generated/graphql';
import axios, { AxiosInstance } from 'axios';

import { Logger } from '../App';
import { getHealthieDayJSDate } from '../helpers/dateHelper';
import { ContactInfo } from '../interfaces/ContactInfo';
import { ExtStore } from '../interfaces/Ext';
import {
  HelpScoutConversation,
  HelpScoutConversationCreatedResponse,
  HelpScoutCustomer,
} from '../interfaces/HelpScout';
import { IneligibleMemberInfo } from '../interfaces/IneligibleMemberInfo';
import { MemberInformation } from '../interfaces/MemberInformation';

const helpScoutAPI: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_ENROLLMENT_API_URL,
});

const wellinksHelpScoutCustomer: HelpScoutCustomer = {
  email: 'developers@wellinks.com',
  firstName: 'Wellinks',
  lastName: 'Enrollment',
};

export const helpScoutProcess = {
  //p1 & p2
  termsOfServiceCompleted: {
    tags: ['TOS collected', 'new member'],
    buildHelpScoutConversation(
      memberInformation: MemberInformation,
      contactInfo: ContactInfo
    ) {
      const helpScoutConversation: HelpScoutConversation = {
        subject: 'Wellinks New Member Sign Up',
        customer: wellinksHelpScoutCustomer,
        mailboxId: Number(process.env.REACT_APP_HELPSCOUT_MAILBOX_ID),
        type: 'email',
        status: 'active',
        threads: [
          {
            type: 'customer',
            customer: wellinksHelpScoutCustomer,
            text: `
              <div><b>A new member has signed up for Wellinks!</b></div>
              <br />
              <b>Insurance Information (Page 1):</b>
              <pre>${prettyPrintObject(memberInformation)}</pre>
              <br />
              <b>Contact Information (Page 2):</b>
              <pre>${prettyPrintObject(contactInfo)}</pre>`,
          },
        ],
        tags: helpScoutProcess.termsOfServiceCompleted.tags,
      };

      return helpScoutConversation;
    },
  },

  verifyIneligibility: {
    tags: ['TOS collected', 'new member'],
    buildHelpScoutConversation(
      memberInformation: MemberInformation,
      contactInfo: IneligibleMemberInfo
    ) {
      const helpScoutConversation: HelpScoutConversation = {
        subject: 'New Member Interest Self Sign Up Form Submission',
        customer: wellinksHelpScoutCustomer,
        mailboxId: Number(process.env.REACT_APP_HELPSCOUT_MAILBOX_ID),
        type: 'email',
        status: 'active',
        threads: [
          {
            type: 'customer',
            customer: wellinksHelpScoutCustomer,
            text: `
              <div><b>A new submission on the ineligible members form was made. Please verify there are not typos with the existing eligibility file.</b></div>
              <br />
              <b>Insurance Information (Page 1):</b>
              <pre>${prettyPrintObject(memberInformation)}</pre>
              <br />
              <b>Contact Information (Page 2):</b>
              <pre>${prettyPrintObject(contactInfo)}</pre>`,
          },
        ],
        tags: helpScoutProcess.verifyIneligibility.tags,
      };

      return helpScoutConversation;
    },
  },
  //p3
  accountCreated: {
    tags: [
      'TOS collected',
      'new member',
      'create in platform',
      'healthie activated',
    ],
    buildNote(
      email: string,
      extState: ExtStore,
      memberInformation: MemberInformation
    ) {
      return `
          <div><b>Account Information Submitted (Page 3)</b></div>
          <br />
          <div><strong>Account Email:</strong> ${email}</div>
          <div><strong>WellinksID:</strong> ${
  extState.Wellinks.wellinksID ??
            'Error creating Hubble Account, please review and create manually'
}</div>
          <div><strong>HealthieID:</strong> ${extState.Healthie.userID}</div>
          <div><strong>ContractsPatientsID:</strong> ${
  extState.Wellinks.contractsPatientsID ??
            'No contract assignment made, must manually verify member\'s identity before assigning to contract for billing.'
}</div>
          <div><strong>PartnerName:</strong> ${
  memberInformation.enrollmentAffiliate?.display_string
}</div>
          <div><strong>ContractName:</strong> ${
  extState.Wellinks.contractName
}</div>`;
    },
  },
  //p4
  kickOffScheduled: {
    tags: [
      'TOS collected',
      'new member',
      'create in platform',
      'healthie activated',
      'kick off scheduled',
    ],
    buildNote(
      email: string,
      appointmentBookingResult: CompleteCheckoutMutation
    ) {
      const appointment =
        appointmentBookingResult.completeCheckout?.appointment;
      return `
        <div><strong>Member has scheduled their kickoff session (Page 4)</strong></div>
        <br />
        <div><strong>Appointment Scheduled:</strong> ${getHealthieDayJSDate(
    appointment?.date
  ).format('dddd MMMM D, YYYY')} 
        ${getHealthieDayJSDate(appointment?.start).format(
    'h:mm'
  )} - ${getHealthieDayJSDate(appointment?.end).format('h:mm a')}</div>
        <div><strong>Assigned coach:</strong> ${
  appointmentBookingResult.completeCheckout?.appointment?.provider
    ?.full_name
}</div>
      `;
    },
  },
};

export async function createHelpScoutConversation(
  helpScoutConversation: HelpScoutConversation
): Promise<HelpScoutConversationCreatedResponse> {
  try {
    const response = await helpScoutAPI.post(
      'helpscout/conversation/create',
      helpScoutConversation
    );
    return response.data.data;
  } catch (error) {
    Logger.error(
      error as Error,
      'Failed to create conversation with HelpScout.',
      'HelpScout API.'
    );
    throw new Error(`Unable to create conversation: ${error}`);
  }
}

export async function createHelpScoutNote(
  conversationID: number,
  text: string
) {
  try {
    const response = await helpScoutAPI.post(
      'helpscout/conversation/note/create',
      {
        conversationID,
        text,
      }
    );

    return response.status >= 200 && response.status < 300;
  } catch (error) {
    Logger.error(
      error as Error,
      'Failed to create note with HelpScout.',
      'HelpScout API.'
    );
    return false;
  }
}

export async function createHelpScoutConversationTags(
  conversationID: number,
  tags: string[]
) {
  try {
    const response = await helpScoutAPI.put(
      'helpscout/conversation/tags/create',
      {
        conversationID,
        tags,
      }
    );

    return response.status >= 200 && response.status < 300;
  } catch (error) {
    Logger.error(
      error as Error,
      'Failed to tag conversation with HelpScout.',
      'HelpScout API.'
    );
    return false;
  }
}

export async function createHelpScoutCustomer(
  helpScoutCustomer: HelpScoutCustomer
): Promise<number | null> {
  try {
    const response = await helpScoutAPI.post(
      'helpscout/customer/create',
      helpScoutCustomer
    );
    return response.data.data.customerID;
  } catch (error) {
    Logger.error(
      error as Error,
      'Failed to create customer with HelpScout.',
      'HelpScout API.'
    );
    return null;
  }
}

export async function associateHelpScoutConversation(
  conversationID: number,
  customerID: number
) {
  try {
    const response = await helpScoutAPI.patch(
      'helpscout/conversation/associate',
      {
        conversationID,
        customerID,
      }
    );

    return response.status >= 200 && response.status < 300;
  } catch (error) {
    Logger.error(
      error as Error,
      'Failed to associate conversation with customer HelpScout.',
      'HelpScout API.'
    );
    return false;
  }
}

const prettyPrintObject = (object: any) => {
  const replacer = (key: any, value: any) => {
    if (value === null || value === '') {
      return undefined;
    }
    return value;
  };
  return JSON.stringify(object, replacer, 2);
};
