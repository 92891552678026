import React from 'react';

import { Box } from '@mui/material';

interface AvatarProps {
  height: number;
  width: number;
  imageURL?: string | undefined;
  fullName: string;
  alternateString: string;
}

const initials = (fullName: string) => {
  return fullName
    .split(' ')
    .map((n) => n.charAt(0).toUpperCase())
    .join('');
};

const Avatar: React.FC<AvatarProps> = ({
  imageURL = null,
  fullName,
  height,
  width,
  alternateString,
}) => {
  return (
    <Box
      height={height}
      width={width}
      className="avatarContainer"
      textAlign={'center'}
    >
      {imageURL && imageURL != 'original/missing.png' ? (
        <img src={imageURL} className="avatar" alt={alternateString} />
      ) : (
        <>{initials(fullName)}</>
      )}
    </Box>
  );
};

export default Avatar;
