import axios, { AxiosInstance } from 'axios';

import { Logger } from '../App';
import { EnrollmentAffiliate } from '../interfaces/EnrollmentAffiliate';
import { Gender } from '../interfaces/Gender';
import {
  CreateMemberRequest,
  CreateMemberResponse,
  SaveMemberSignupHistoryRequest,
  SaveMemberSignupHistoryResponse,
} from '../interfaces/Hubble';
import { SaveLeadRequest, SaveLeadResponse } from '../interfaces/Lead';
import {
  MemberEligibilityRequest,
  MemberEligibilityResponse,
} from '../interfaces/MemberEligibility';
import { USState } from '../interfaces/USStaate';

const enrollmentAPI: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_ENROLLMENT_API_URL,
});

export async function getEnrollmentAffiliates(): Promise<
  EnrollmentAffiliate[]
  > {
  try {
    const response = await enrollmentAPI.get('enrollmentAffiliates');
    const enrollmentAffiliates: EnrollmentAffiliate[] = response.data.data;
    return enrollmentAffiliates;
  } catch (error) {
    Logger.error(
      error as Error,
      'Failed to get a list of enrollment affiliates.',
      'Enrollment API.'
    );
    throw new Error(`Unable to fetch affiliates: ${error}`);
  }
}

export async function getGenders(): Promise<Gender[]> {
  try {
    const response = await enrollmentAPI.get('genders');
    const genders: Gender[] = response.data.data;
    return genders;
  } catch (error) {
    Logger.error(
      error as Error,
      'Failed to get a list of genders.',
      'Enrollment API.'
    );
    throw new Error(`Unable to fetch genders: ${error}`);
  }
}

export async function getStates(): Promise<USState[]> {
  try {
    const response = await enrollmentAPI.get('states');
    const states: USState[] = response.data.data;
    return states;
  } catch (error) {
    Logger.error(
      error as Error,
      'Failed to get a list of states.',
      'Enrollment API.'
    );
    throw new Error(`Unable to fetch states: ${error}`);
  }
}

export async function hubbleUserSignup(
  request: CreateMemberRequest
): Promise<CreateMemberResponse> {
  try {
    const response = await enrollmentAPI.post('member', request);
    const signup: CreateMemberResponse = response.data.data;
    return signup;
  } catch (error) {
    Logger.error(
      error as Error,
      'Failed to create a user in Hubble.',
      'Enrollment API.'
    );
    throw new Error(`Unable to create a user in Hubble: ${error}`);
  }
}

export async function checkMemberEligibility(
  request: MemberEligibilityRequest
): Promise<MemberEligibilityResponse> {
  try {
    const eligibilityURI =
      'membershipEligibility?first_name=' +
      request.first_name +
      '&last_name=' +
      request.last_name +
      '&date_of_birth=' +
      request.date_of_birth +
      '&enrollment_affiliate_id=' +
      request.enrollment_affiliate_id;
    const response = await enrollmentAPI.get(eligibilityURI);
    const eligibility: MemberEligibilityResponse = response.data.data;
    return eligibility;
  } catch (error) {
    Logger.error(
      error as Error,
      'Failed to check for kMemeber Eligibility.',
      'Enrollment API.'
    );
    throw new Error(`Unable to check for Memeber Eligibility: ${error}`);
  }
}

export async function saveLead(
  request: SaveLeadRequest
): Promise<SaveLeadResponse> {
  try {
    const response = await enrollmentAPI.post('patientLead', request);
    const lead: SaveLeadResponse = response.data.data;
    return lead;
  } catch (error) {
    Logger.error(
      error as Error,
      'Failed to save Lead in Hubble.',
      'Enrollment API.'
    );
    throw new Error(`Failed to save Lead in Hubble.: ${error}`);
  }
}

export async function saveMemberSignupHistory(
  request: SaveMemberSignupHistoryRequest
): Promise<SaveMemberSignupHistoryResponse> {
  try {
    const response = await enrollmentAPI.post('memberSignupHistory', request);
    const signupHistory: SaveMemberSignupHistoryResponse =
      response.data.message;
    return signupHistory;
  } catch (error) {
    Logger.error(
      error as Error,
      'Failed to save Member Signup History in Hubble.',
      'Enrollment API.'
    );
    throw new Error(
      `Failed to save Member Signup History in Hubble.: ${error}`
    );
  }
}
