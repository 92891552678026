import { useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import { scrollToTop } from '../helpers/pageHelpers';

const TopScroll = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    scrollToTop();
  }, [pathname]);

  return null;
};

export default TopScroll;
