import React from 'react';

import styled from '@emotion/styled';
import { Check, Circle } from '@mui/icons-material';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Step from '@mui/material/Step';
import StepConnector, {
  stepConnectorClasses,
} from '@mui/material/StepConnector';
import { StepIconProps } from '@mui/material/StepIcon';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
const steps = [
  {
    step: 'Is Wellinks in your plan?',
    text: 'First, let\'s find out if we partner with your health insurance.',
  },
  {
    step: 'Add your contact info',
    text: 'Tell us where to send your Welcome Kit.',
  },
  {
    step: 'Secure your account',
    text: 'Almost done! Add an email and a password.',
  },
  {
    step: 'Set up your first session',
    text: 'Pick a day and time for a 45-minute session with your Health Coach.',
  },
];

export default function LinearStepper({ activeStep }: { activeStep: number }) {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <Stepper
        activeStep={activeStep}
        sx={{ width: 600 }}
        alternativeLabel
        connector={<Connector />}
      >
        {steps.map((item) => (
          <Step key={item.step}>
            <StepLabel StepIconComponent={StepIcon}></StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}

const Connector = styled(StepConnector)(() => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 18px)',
    right: 'calc(50% + 18px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#3A66FF',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#3A66FF',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderTopWidth: 2,
    borderRadius: 1,
  },
}));

const StepIconRoot = styled('div')<{ ownerState: { active?: boolean } }>(
  ({ ownerState }) => ({
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(ownerState.active && {
      color: '#3A66FF',
    }),
    '& .StepIcon-completedIcon': {
      color: '#FFFFFF',
      zIndex: 1,
      fontSize: 24,
    },
    '& .StepIcon-circle': {
      fontSize: 15,
      ...(ownerState.active && {
        color: '#3A66FF',
      }),
    },
  })
);

const StepperPerimeter = styled(Avatar)<{
  ownerState: { active?: boolean; completed?: boolean };
}>(({ ownerState }) => ({
  zIndex: '55',
  border: '2px solid #bdbdbd',
  background: 'none',
  ...(ownerState.active && {
    borderColor: '#3A66FF',
  }),
  ...(ownerState.completed && {
    borderColor: '#3A66FF',
    backgroundColor: '#3A66FF',
  }),
}));

function StepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  return (
    <StepIconRoot ownerState={{ active }} className={className}>
      <StepperPerimeter ownerState={{ active, completed }}>
        {completed ? (
          <Check className="StepIcon-completedIcon" />
        ) : (
          active && <Circle className="StepIcon-circle" />
        )}
      </StepperPerimeter>
    </StepIconRoot>
  );
}
