import * as React from 'react';

import { useBeforeUnload } from 'react-router-dom';

export default function useNavBlocker(
  message: string | null | undefined | false
) {
  useBeforeUnload(
    React.useCallback(
      (event) => {
        if (typeof message === 'string') {
          event.preventDefault();
          event.returnValue = message; //this will work with some browsers, but most do not respect this as a security measure.
        }
      },
      [message]
    ),
    { capture: true }
  );
}
